/* mobile css */

@media screen and (max-width : 426px) {

    .addressKognics {
        display: none;
    }

    .main-container {
        height: auto;
    }

    .header {
        background-color: transparent;
        position: fixed;
        top: 0vw;
        left: 0vw;
        width: 100vw;
        /* height: 15.5vw; */
        overflow: hidden;
        z-index: 2000;
        display: flex;
        align-items: center;
    }

    .header a {
        padding-top: 1rem;
        width: 100px;
    }

    .header-mobile-color {
        background-color: white;
        position: fixed;
        top: 0vw;
        left: 0vw;
        width: 100vw;
        /* height: 15.5vw; */
        overflow: hidden;
        z-index: 2000;
        display: flex;
        align-items: center;
    }

    .all-link {
        display: none;
    }

    .show {
        display: block;
    }

    .popup {
        position: fixed;
        z-index: 9999;
        width: 100vw;
        height: 1235vw;
        border-radius: 0 2vw 2vw 0;
        background-color: white;
        box-shadow: 3px 5px 17px -2px rgba(45, 114, 136, 0.5);
    }

    .popup ul {
        width: 90vw;
        color: #2D7288;
        font-size: 3.8vw;
        position: relative;
        top: 12vw;
        left: 5vw;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5vw;
        margin: 0;
        padding: 0;
    }

    .popup ul li {
        position: relative;
        left: 0vw;
    }

    .popup img {
        position: relative;
        top: 5.3vw;
        left: 5vw;
    }

    .home {
        display: block;
        position: relative;
        left: 5vw;
        width: 5.5vw;
        height: 5vw;
    }

    .logo {
        display: none;
    }

    .DropDown {
        display: none;
    }

    /* Part 1 */
    .container1 {
        position: relative;
        top: 15.5vw;
        width: 100vw;
        height: 50vw;
        display: flex;
        flex-direction: row;
    }

    .div1 {
        min-width: 71vw;
        box-shadow: -5px 8.8px 14px rgba(45, 114, 136, 0.45);
    }

    .bg1 {
        min-width: 76vw;
        background-size: 170vw 90vw;
    }

    .text1 {
        font-weight: 600;
        font-size: 4vw;
        width: 45vw;
        height: 11vw;
        position: relative;
        top: 6vw;
        left: 12.5vw;
        line-height: 5.5vw;
    }

    .text2 {
        font-weight: 400;
        font-size: 2.9vw;
        width: 53vw;
        height: 14vw;
        position: relative;
        top: 9vw;
        left: 12.5vw;
        line-height: 4.5vw;
        color: #465154;
    }

    .div1 .button {
        width: 22vw;
        height: 5vw;
        border-radius: 0.8vw;
        position: relative;
        top: 11vw;
        left: 12.5vw;
        font-weight: 300;
        font-size: 2.5vw;
        line-height: 2vw;
    }

    .div1 .button img {
        width: 2.2vw;
    }

    .div2 {
        z-index: -1;
        top: 2vw;
        left: -20vw;
        min-width: 60vw;
        height: 46vw;
    }

    .bg2 {
        transform: skew(-20deg) rotateY(180deg);
        background: linear-gradient(rgba(248, 255, 254, 0.5), rgba(45, 114, 136, 0.5)), url(http://d2s9pyuyxqivbc.cloudfront.net/pexels-anna-shvets-3986984+1.png);
        background-size: 55vw;
        /* background-repeat: no-repeat; */
        /* background-position: top 0vw left -32vw; */
        z-index: -1;
        opacity: 0.75;
    }

    .div2 .play {
        /* transform: rotateY(180deg); */
        transform: skew(-20deg);
        top: 19vw;
        left: 19vw;
        width: 15.5vw;
        height: 10vw;
        font-size: 2.6vw;
        font-weight: 600;
        color: #2D7288;
        z-index: 10;
        opacity: 1;
    }

    .div2 .play img {
        width: 5.5vw;
        opacity: 1;
    }

    .container2 {
        width: 100vw;
        height: 145vw;
        position: relative;
        top: 15.5vw;
        z-index: 100;
        flex-direction: column;
    }

    .part1 {
        width: 100vw;
    }

    .part1 .box1 {
        width: 37vw;
        height: 37vw;
        top: 42vw;
        left: 27vw;
    }

    .part1 .box2 {
        width: 32vw;
        height: 36vw;
        top: 6vw;
        left: 42vw;
    }

    .part1 .box2 img {
        width: 56vw;
    }

    .part2 {
        z-index: 100;
    }

    .part2 .text3 {
        position: relative;
        top: -58vw;
        left: 16vw;
        width: 68vw;
        text-align: center;
        height: 15vw;
        font-family: "Raleway", sans-serif;
        font-weight: 500;
        line-height: 7.5vw;
        font-size: 5.5vw;
        color: #1F5262;
    }

    .part2 .line {
        position: relative;
        top: -5.6vw;
        left: 16.8vw;
        z-index: -1;
        width: 23.5vw;
    }

    .part2 .text4 {
        top: 7vw;
        left: 14.5vw;
        line-height: 5.5vw;
        width: 71vw;
        height: 31vw;
        font-size: 3.5vw;
        text-align: center;
    }

    .part2 .text5 {
        font-size: 3.5vw;
        width: 30vw;
        height: 6vw;
        font-weight: 600;
        line-height: 6vw;
        top: 15vw;
        left: 38vw;
    }

    .text5 .arrow {
        position: absolute;
        width: 3.5vw;
        top: 1vw;
        left: 18vw;
    }

    .container3 {
        position: relative;
        top: 28vw;
        height: 430vw;
    }

    .rect1 {
        height: 430vw;
        left: 0vw;
        border-radius: 5vw 20vw 5vw 5vw;
    }

    .rect1 img {
        display: none;
    }

    .rect2 {
        background-color: transparent;
        box-shadow: none;
        min-width: 100vw;
        top: -8vw;
        height: 434vw;
        left: -100vw;
        border-radius: 20vw 18vw 20vw 20vw;
        transform: none;
    }

    .rect2 .backImg {
        display: block;
        min-width: 100vw;
        height: 430vw;
        position: relative;
    }

    .rect2 .content {
        display: block;
        width: 120vw;
        height: 380vw;
        position: absolute;
        top: 20vw;
        left: -10vw;
        transform: rotateY(25deg);
    }

    .rect2 .content .text6 {
        width: 90vw;
        height: 8vw;
        font-size: 7.8vw;
        line-height: 7.8vw;
        text-align: center;
        font-weight: 500;
        top: 11vw;
        left: 18vw;
    }

    .rect2 .content .text6 img {
        width: 23.5vw;
        top: -5.3vw;
        left: 26.5vw;
    }

    .rect2 .content .cards {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;
        row-gap: 13vw;
        column-gap: 0vw;
        left: 10vw;
        width: 100vw;
        height: 360vw;
        top: 20vw;
    }

    .all-card {
        min-width: 90vw;
        min-height: 55vw;
        justify-content: flex-start;
        gap: 4vw;
    }

    .all-card .logos {
        min-width: 50vw;
        height: 24vw;
    }

    .all-card .heading {
        min-width: 40vw;
        height: 7vw;
        font-size: 4.5vw;
        font-weight: 700;
    }

    .all-card .blue-line {
        width: 10vw;
    }

    .all-card .text {
        /* background-color: red; */
        min-width: 60vw;
        min-height: 12vw;
        font-size: 4vw;
        font-weight: 400;
    }

    .see-link {
        width: 31vw;
        text-align: center;
        font-size: 3.5vw;
        left: 45vw;
        top: 1vw;
        font-weight: 600;
    }

    .see-link a img {
        margin-left: 1.5vw;
        width: 4vw;
    }

    .part4 {
        height: 25vw;
        position: relative;
        top: 35vw;
    }

    .part4 .text8 {
        position: relative;
        top: 12vw;
        left: 17vw;
        width: 66vw;
        height: 7vw;
        font-size: 5.1vw;
        text-align: center;
        line-height: 7vw;
        font-weight: 500;
    }

    .part4 .line {
        top: -5.3vw;
        width: 13vw;
    }

    .card-holder {
        position: relative;
        top: 35vw;
        height: 70vw;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .card-container {
        position: relative;
        min-width: 190vw;
        left: 0vw;
        height: 70vw;
        gap: 10vw;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .card2 {
        min-width: 44vw;
        min-height: 60vw;
        border-radius: 7vw;
    }

    .card2 .lines {
        width: 50vw;
        height: 70vw;
        position: relative;
        top: -0.5vw;
        left: 0vw;

    }

    .card2 .k_logo {
        width: 25.5vw;
        top: -54vw;
        left: 9.25vw;
    }

    .card2 .heading {
        font-family: "Jost", sans-serif;
        width: 25.5vw;
        height: 15vw;
        line-height: 7.5vw;
        font-size: 5.2vw;
        font-weight: 300;
        top: -52vw;
        left: 9.25vw;
    }

    .card1 {
        min-width: 35vw;
        height: 49vw;
        border-radius: 7vw;
    }

    .card1 .demo {
        width: 35vw;
        min-height: 52vw;
        top: -20vw;
    }

    .card1 .play {
        width: 6vw;
        min-height: 6vw;
        top: -47vw;
        left: 14.5vw;
        z-index: 30;
    }

    .card1 .heading {
        width: 23vw;
        min-height: 10.5vw;
        top: -38vw;
        left: 6vw;
        font-size: 3.5vw;
        font-weight: 500;
        line-height: 5.1vw;
    }

    .card1 .gradient {
        width: 36vw;
        min-height: 48.5vw;
        top: 35vw;
    }

    .part5 {
        position: relative;
        top: 45vw;
        height: 60vw;
    }

    .part5 .text7 {
        width: 55vw;
        height: 21.5vw;
        font-weight: 500;
        font-size: 6vw;
        line-height: 11vw;
    }

    .part5 .bold {
        font-weight: 700;
    }

    .part5 .size-76 {
        font-size: 8vw;
    }

    .part5 .line {
        width: 21vw;
        top: -9vw;
        left: 14.5vw;
    }

    .part6 {
        position: relative;
        top: 50vw;
        height: 150vw;
    }

    .part6 .inner-container {
        width: 100vw;
        height: 125vw;
        border-radius: 7vw;
    }

    .part6 .inner-container .text {
        top: 15vw;
        left: 28vw;
        width: 43vw;
        height: 8vw;
        font-size: 5.2vw;
        line-height: 7.5vw;
        font-weight: 500;
    }

    .inner-container .line {
        top: -6vw;
        width: 23.5vw;
        left: -5.5vw;
    }

    .inner-container .swiper-container .glass-cards {
        width: 100vw;
        height: 75vw;
        top: 25vw;
    }

    .swiper-container .swiper-button-next,
    .swiper-container .swiper-button-prev {
        background-color: transparent;
        width: 7.5vw !important;
        height: 7.5vw !important;
        border-radius: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .swiper-container .swiper-button-next:hover,
    .swiper-container .swiper-button-prev:hover {
        background-color: #d2d5d6;
    }

    .swiper-container .swiper-button-next::after,
    .swiper-container .swiper-button-prev:after {
        font-size: 4vw !important;
    }

    .swiper-container .glass-card {
        /* background-color: red; */
        min-width: 38vw;
        height: 52vw;
    }

    .swiper-container .glass-card .container {
        /* background-color: red; */
        width: 33vw;
        height: 43vw;
        z-index: 9;
        gap: 6vw;
    }

    .glass-card .container svg {
        width: 10vw;
        height: 10vw;
    }

    .para {
        width: 33vw;
        height: 10vw;
        font-size: 2.5vw;
        line-height: 4vw;
        text-align: center;
    }

    .swiper-container .swiper-slide-next {
        width: 46vw !important;
        margin-right: 8vw;
        margin-left: 15vw;
    }

    .swiper-container .swiper-slide {
        position: relative;
        left: -23vw;
        top: 4vw;
    }

    .swiper-container .swiper-slide-next .glass-card {
        min-width: 46vw !important;
        height: 65vw !important;
        border-radius: 2.5vw;
        background: linear-gradient(157.08deg,
                rgba(255, 255, 255, 0.5) 5.56%,
                rgba(255, 255, 255, 0) 47.01%,
                rgba(45, 114, 136, 0) 66.3%,
                rgba(45, 114, 136, 0.5) 97.52%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0.5vw;
        /* background-color: red; */

    }

    .swiper-container .swiper-slide-next .glass-card .container {
        z-index: 100;
        width: 35vw;
        height: 55vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8vw;
        /* background-color: red; */
    }

    .swiper-container .swiper-slide-next .glass-card svg {
        width: 15vw;
        height: 15vw;
        fill: white !important;
        opacity: 1;
        filter: drop-shadow(-0.2vw -0.1vw 0.1vw rgba(184, 215, 225, 1));
    }

    .swiper-container .swiper-slide-next .glass-card .head {
        /* background-color: red; */
        width: 40vw;
        height: 5vw;
        text-align: center;
        color: white;
        text-shadow: -2px -1px 3px #A6C9D5;
        font-size: 4.5vw;
        line-height: 5vw;
    }

    .swiper-container .swiper-slide-next .glass-card .para {
        /* background-color: pink; */
        width: 35vw;
        height: 10vw;
        font-family: "Jost", sans-serif;
        font-size: 2.5vw;
        font-weight: 300;
        color: #afafaf;
        text-align: center;
    }

    .heading1 {
        /* background-color: red; */
        width: 35vw;
        height: 6vw;
        font-size: 6vw;
        font-family: "Jost", sans-serif;
        font-weight: 600;
        text-align: center;
        color: #afafaf;
        letter-spacing: 0.2vw;
        line-height: 5.5vw;
        text-shadow: -0.2vw -0.1vw 0.3vw rgba(211, 211, 211, 0.6);
    }

    .heading2 {
        /* background-color: red; */
        width: 35vw;
        height: 5vw;
        font-size: 5vw;
        font-family: "Jost", sans-serif;
        font-weight: 600;
        text-align: center;
        color: #afafaf;
        line-height: 5vw;
        text-shadow: -0.2vw -0.1vw 0.3vw rgba(211, 211, 211, 0.6);
    }

    .heading3 {
        /* background-color: pink; */
        width: 36vw;
        height: 5vw;
        font-size: 4vw;
        font-family: "Jost", sans-serif;
        font-weight: 600;
        text-align: center;
        color: #afafaf;
        line-height: 5vw;
        text-shadow: -0.2vw -0.1vw 0.3vw rgba(211, 211, 211, 0.6);
    }

    .part7 {
        position: relative;
        top: 50vw;
        height: 140vw;
        flex-direction: column;
    }

    .part7 .frame-container {
        position: relative;
        top: 25vw;
        width: 100vw;
        height: 70vw;
    }

    .part7 .frame-container .circle {
        width: 70vw;
        height: 70vw;
    }

    .part7 .frame-container .frame {
        width: 70vw;
        height: 53vw;
    }

    .part7 .frame-container .lamination {
        width: 69.5vw;
        height: 52.5vw;
    }

    .part7 .text-container {
        width: 100vw;
        height: 50vw;
        align-items: center;
    }

    .part7 .text-container .heading {
        width: 32vw;
        height: 8vw;
        font-size: 5.2vw;
        font-weight: 500;
        top: -85vw;
        line-height: 8vw;
    }

    .part7 .text-container .line {
        top: -6.5vw;
        width: 15vw;
        left: 14vw;
    }

    .part7 .text-container .input-container {
        flex-direction: column;
        align-items: center;
        gap: 3vw;
        position: relative;
        top: 8vw;
    }

    .part7 .text-container .input-container .input {
        width: 70vw;
        min-height: 7vw;
        border-radius: 1vw;
    }

    .part7 .text-container .input-container .input input {
        width: 67vw;
        height: 5vw;
        left: 0.5vw;
        font-size: 2.5vw;
        text-align: center;
    }

    .part7 .text-container .input-container button {
        width: 20vw;
        min-height: 7vw;
        border-radius: 1vw;
    }

    .part7 .text-container .input-container button .arrow {
        width: 5vw;
    }

    /* .footer-container {
        position: relative;
        top: 45vw;
        height: auto;
    } */

    .footer {
        height: 126vw;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 0.5vw;
        padding-left: 4vw;
    }

    .footer .bold {
        font-weight: 600;
        font-size: 4.2vw;
    }

    .footer .all {
        font-weight: 200;
        font-size: 3.6vw;
        gap: 2.5vw;
    }

    .footer .box1 {
        position: relative;
        top: 10vw;
        width: 90vw;
        height: 35vw;
    }

    .box1 img {
        width: 24vw;
        height: 9vw;
        top: -1.3vw;
        left: -1vw;
        background-color: transparent;
    }

    .box1 .desc {
        width: 70vw;
        height: 11vw;
        font-size: 4vw;
        line-height: 5.5vw;
        text-align: justify;
    }

    .box1 .label {
        width: 56vw;
        height: 5vw;
        font-size: 3vw;
        line-height: 5vw;
    }

    .box1 .label-input {
        gap: 0.6vw;
    }

    .box1 .input-container {
        gap: 1vw;
    }

    .box1 .input-container input {
        width: 70vw;
        height: 7vw;
        border-radius: 1vw;
    }

    .box1 .input-container button {
        width: 14vw;
        height: 8vw;
        border-radius: 1vw;
        font-family: "lato", sans-serif;
        font-weight: 400;
        font-size: 3vw;
    }

    .footer .contact {
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 90vw;
        column-gap: 40vw;
        position: relative;
        row-gap: 10vw;
        top: 15vw;
        left: 1vw;
    }

    .footer .contact .box2 {
        width: 20vw;
        height: 30vw;
        gap: 3vw;
    }

    .footer .contact .box2 .all .partner {
        display: none;
    }

    .footer .contact .box3 {
        width: 20vw;
        height: 30vw;
    }

    .footer .contact .box4 {
        width: 40vw;
        height: 30vw;
    }

    .footer .contact .box4 .all {
        gap: 2.8vw;
    }

    .footer .all .icons .circle {
        width: 5vw;
        height: 5vw;
        border: 0.3vw solid white;
    }

    .footer .all .icons {
        gap: 3vw;
    }

    .footer .all .icons .circle i {
        font-size: 2.5vw;
    }

    .copyright {
        height: 9vw;
    }

    .copyright div {
        font-size: 3vw;
        /* width: 55vw; */
        height: 4vw;
        font-weight: 300;
    }
}