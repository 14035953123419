.CourseLibrary_main {
    width: 100%;
    height: 100%;
    text-align: center;
    box-shadow: inset inset 0px 0px 20px 30px #DCFFF9;
    -webkit-box-shadow: inset 0px 0px 20px 30px #DCFFF9;
    /* border: 2px solid red; */
}

.CourseLibrary {
    width: 99%;
    margin: auto;
    transform: scale(0.9);
}

.CourseLibrary_main>h2 {
    font-family: Atma;
    font-size: 95px;
    font-weight: 600;
    line-height: 143px;
    letter-spacing: 0em;
    text-align: center;
    color: #33544E;
    padding-top: 2rem;
}

.ArrowStyle {
    position: relative;
    left: 30px;
    top: 5px;
}

.content-container {
    padding: 1rem;
}

.ImageContent {
    padding-left: 2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}


.title {
    font-family: 'Raleway';
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #1F5262;
}

.container-inner {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 999;


    .swiper-wrapper {
        z-index: 999;


    }

    .slider-Container {
        width: 30rem !important;
        height: 450px;
        border-radius: 15px;
    }

    .swiper-slide-next {
        margin-right: inherit;
    }



    .swiper-button-prev {
        left: 47%;
        background-image: url('../Images/NewHome/Content-Library/prev.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }

    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-next {
        right: 47%;
        background-image: url('../Images/NewHome/Content-Library/nex.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;

    }

    .swiper-button-next::after {
        display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
        z-index: 999;
        position: absolute;
        top: 80%;
    }
}


@media (min-width:1069px) and (max-width :1440px) {

    .ImageContent {
        padding-left: 2.5rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
    }

    .CourseLibrary_main>h2 {
        font-size: 40px;
    }
}

@media (min-width:320px) and (max-width :430px) {

    .titleContainer {
        margin-left: 1rem !important;
    }

    .mobileTitle {
        font-size: 18px;
        font-weight: 600;
        line-height: inherit !important;
    }

    .title {
        font-size: 18px;
        font-weight: 600;
    }

    .ImageContent {
        padding-left: inherit !important;
    }

    .CourseLibrary_main>h2 {
        font-size: 35px !important;
        line-height: inherit !important;
    }


    .imageConatiner-inner {
        height: 150px !important;
        width: inherit !important;
    }

    .ArrowStyle {
        left: 10px !important;
        top: 5px !important;
    }
}