@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=EB+Garamond&family=Lobster&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.feedback-rectangle {
    display: block;
    justify-content: center;
    padding-top: 1vw;
    align-items: center;
    height: auto;
    width: auto;
}
.feedback-headding {
    margin-top: 10%;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFF0EF 100%);
    height: 11vw;
    width: 100vw;
}

.feedback-headding h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 600;
    font-size: 95px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #600D08;
  
}

.createfeedbackimg {
    text-align: center;
    height: auto;
    padding-top: 2vw;
}

.create-feedback {
    background: #FFF0EF;
    height: auto;
    width: 100vw;
}

.smarter-feedback {
    background: linear-gradient(180deg, #FFF0EF 0%, #FFFFFF 100%);
    padding: 5rem 3rem;
    width: 100vw;
}

.smarter-feedback h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #600D08;
}

.bold {
    font-weight: bold;
}

.three-carrds {
    background: #FFFFFF;
    height: auto;
    width: 100vw;
}

.blok {
    border: 2px solid #600D08;
    border-radius: 45px;
    box-shadow: 0px 0px 5px 15px #FCE4E2;
    display: inline-block;
    box-sizing: border-box;
    margin: 3vw;
    padding-top: 2vw;
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 19vw;
    height: 18vw;
}

.twocard {
    display: flex;
    justify-content: center;
    align-items: center;
}

.paragra {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 550;
    font-size: 1.1vw;
    line-height: 150%;
    text-align: center;
    color: #814845;
    padding: 0.5vw;
    margin: 0.5vw;
}

.cardheadings {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 150%;
    text-align: center;
    color: #600D08;
}

.dotlineimgg-feedback {
        height: 0.4vw;
        width: 17vw;
    
}


.time-savingg {
    background: #FFFFFF;
    height: 200px;
    width: 100vw;
}

.time-savingg h1 {

    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #600D08;
    height: 60px;
    padding-top: 20px;

}

.boldd {
    font-weight: bold;
}

.consolidated-platformm-feedback {
    background: #FFFFFF;
    height: 1000px;
    width: 100vw;
}

.consolidatedd-feedback {
    text-align: center;
    height: 800px;
}

.lastdiv {
    background: linear-gradient(180deg, #FFFDFD 0%, #FFF0EF 100%);
    height: 10vw;
    width: 100vw;
}

.mobfeedb {
    display: none;
}

.mobconsfeed {
    display: none;
}