/* --------- */
/* Navbar Section  */
/* --------- */
button:focus {
    border: none;
}

.Finance_Content {
    width: 100vw;
    position: relative;
    overflow: hidden;
}

/* .Finance_main{
    margin-top: -15%;
} */
.header_finance {
    height: 15vh;
}

.sticky {
    background-color: white !important;
    transition: all 0.3s ease-out 0s !important;
}

.navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
    color: transparent !important;

}

.navbar-toggler span {
    display: block !important;
    background-color: #2A7086 !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}

@media (max-width: 767px) {
    .navbar {
        padding: 0rem 1rem !important;
        font-size: 1.4rem !important;
        background-color: #ffff !important;
    }

    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

.navbar-brand {
    color: rgb(250, 250, 250) !important;
}

.navbar-nav .nav-link {
    font-family: Atma;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #0B322B !important;
    // padding-right: 1rem !important;
    padding-left: 1rem !important;
}


// .nav-link {
//     padding: 0.8rem 1rem !important;
// }

@media (max-width: 767px) {
    .nav-link {
        padding: 0.7rem 1rem !important;
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}



.fork-btn-inner {
    // height: 50px;
    border-radius: 30px !important;
    line-height: 35px !important;
    background-color: #44B0C8 !important;
    padding: 0.25rem 1.1rem !important;
    vertical-align: middle !important;
    text-align: center !important;
    border: 2px dashed #2D7288 !important;
    box-shadow: 0px 2px 8px 0px #00000040;
    font-size: 22px !important;
    font-weight: 700 !important;
}
@media (min-width:320px) and (max-width:780px) {
    .fork-btn-inner {
        font-size: 18px !important;
    }
}
.fork-btn-inner:hover {
    // transform: skewY(-2.5deg) !important;
    background-color: #44B0C8 !important;
    border-color: #44B0C8 !important;
}

.fork-btn-inner::after {
    display: none !important;
}


.nav-item {
    display: flex;
    justify-content: center;
}

.nav-item .dropDown {
    width: 18px;
}

.nav-item .dropDown:hover {
    cursor: pointer;
}

// .navbar-nav .nav-item a::after {
//     content: "";
//     position: relative;
//     display: block;
//     height: 5px;
//     width: 0;
//     border-radius: 16px;
//     background: #c95bf5;
//     bottom: 1px;
//     left: 0;
//     z-index: -1;
//     transition: all 0.3s ease-out 0s;
// }

// .navbar-nav .nav-item a:hover::after {
//     width: 100%;
// }
.foxsitting,
.mobileimg,
.rightSide-mobile,
.courserHome-mobile {
    display: none !important;
}

@media (min-width:760px) and (max-width:780px) {
    .navbar-brand {
        width: 100px;
        padding-left: 1rem;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        transform: scale(0.93);
    }

}

@media (min-width:760px) and (max-width:991px) {

    .navbar {
        padding: 0.3rem 0.9rem !important;
    }

    .navbar-nav .nav-item {
        margin-left: inherit !important;
    }


}

@media (min-width:320px) and (max-width:430px) {

    .mobileimg,
    .courserHome-mobile,
    .rightSide-mobile {
        display: block !important;
    }

    .desktop,
    .rightSide {
        display: none !important;
    }

    .foxsitting {
        display: block !important;
        padding-left: 15%;
    }

    .navbar-nav .nav-item {
        margin-left: 10px;
        margin-right: 10px;
    }

    .fork-btn {
        padding-left: 20%;
    }

    .navbar-collapse {
        border: 2px solid #2A7086;
        background: linear-gradient(0deg, #DEFFF9 44.06%, rgba(222, 255, 249, 0) 100%);

    }

    .navbar-nav .nav-item a {
        text-align: start;
        padding: 2rem;
    }

    .navbar-nav .nav-link {
        padding-bottom: 1.38rem !important;
        border-bottom: 1px solid #2A7086AD;
    }

    .navbar-nav .loginbtn {
        border-bottom: none;
    }

    .nav-item .dropDown {
        margin-left: 50%;
        width: 25px;
    }

    .toggle-container {
        height: auto;
    }

    .toggle-container h2 {
        height: auto;
        font-family: Atma;
        font-size: 20px;
        font-weight: 600;
        line-height: 42px;
    }

    .toggle-container .swiper-horizontal>.swiper-pagination-bullets,
    .toggle-container .swiper-pagination-bullets.swiper-pagination-horizontal,
    .toggle-container .swiper-pagination-custom,
    .toggle-container .swiper-pagination-fraction {
        width: 24%;
        height: 1rem;
        position: absolute;
        left: 45% !important;
        top: 83%;
        background: #FFFFFF;
        border-radius: 51px;
        margin: auto;
        box-shadow: 0px 0px 8px 0px #23A78F;
    }


    .leftSide p,
    .leftSide h3,
    .leftSide .login-btn_home {
        display: none;
    }


    .rightSide-mobile {
        padding-bottom: 5%;

        p {
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #345F56;
        }

        h3 {
            font-family: Atma;
            font-size: 35px;
            font-weight: 400;
            line-height: 53px;
            letter-spacing: 0em;
            text-align: center;
            color: #33544E;
            padding: 1rem;

            span {
                font-family: Atma;
                font-size: 35px;
                font-weight: 700;
                line-height: 53px;
                letter-spacing: 0em;
                text-align: center;

            }

        }

    }

}