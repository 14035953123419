@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=EB+Garamond&family=Lobster&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .quiz-hd{
    margin-top: 10%;
    background: linear-gradient(180deg, #FFFFFF 0%, #E8DFF5 100%, #DBFFF8 100%);
    height: 11vw;
    width: 100vw;
}

.quiz-hd h1{

font-family: 'Atma';
font-style: normal;
font-weight: 600;
font-size: 95px;
line-height: 150%;
align-items: center;
text-align: center;
color: #373140;

}

.quizimg{
    text-align: center;
    height: auto;
    padding-top: 2vw;
    
}
.create-quiz{
    background: #E8DFF5;
    height: auto;
    width: 100vw;
}

.simplify{
    background: linear-gradient(180deg, #E8DFF5 0%, #FFFFFF 100%);
    height: 22vw;
    padding: 4vw;
    width: 100vw;
}

.simplify h1{
    font-family: 'Atma';
font-style: normal;
font-weight: 400;
font-size: 65px;
line-height: 150%;
align-items: center;
text-align: center;
color: #373140;

}
.bold{
    font-weight: bold;
}
.four-cards{
    background: #FFFFFF;
    height: auto;
    width: 100vw;

}
.bloc{
    border: 2px solid #D0C6DE;
    border-radius: 45px;
    box-shadow: 0px 0px 5px 20px#D0C6DE;
    display: inline-block;
    box-sizing: border-box;
    margin: 3vw;
    padding-top: 2vw;
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    width: 19vw;
    height: 18vw;
}
.twos{
    display: flex;
    justify-content: center;
    align-items: center;
}
.paragr-quiz{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1vw;
    line-height: 150%;
    text-align: center;  
    color: #60586D;
    padding: 0.5vw;
    margin: 0.5vw;
}
.cardheading{
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 150%;
    text-align: center;
    color: #373140;
}
.dotlineimg {

    width: 17vw;
    height: 0.4vw;
}


 .tackles{
    background: #FFFFFF;
    height: 200px;
    padding: 4vw;
    width: 100vw;
}

.tackles h1{

font-family: 'Atma';
font-style: normal;
font-weight: 400;

font-size: 65px;
line-height: 150%;
align-items: center;
text-align: center;
color: #373140;

padding-top: 20px;

}
.boldd{
    font-weight: bold;
}

.deliver-content{
    background: #FFFFFF;
    height: auto;
    width: 100vw;
  
}
.deliverimg{
    text-align: center;
    height: auto;

}
.deskquizcons {
    display: block;
    width: 88vw;
    /* height: 92vw; */
    padding: 6vw;
    margin: auto;
}
.last{
    background: linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%);
    height: 10vw;
    width: 100vw;
}

.mobquizcons{
    display: none;
}
.mobcreatequiz{
    display: none;
}