@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=EB+Garamond&family=Lobster&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.course-rectangle {
    display: block;
    justify-content: center;
    padding-top: 10vw;
    align-items: center;
    height: auto;
}

.mobcoursefig {
    display: none;
}

.mobcreatecourse {
    display: none;
}

.deskcreatecourse {
    display: block;
}

.deskcoursefig {
    display: block;
}

.course-head {
    margin-top: 1%;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);
    height: 3vw;
    width: 100vw;
}

.course-head h1 {
    padding-top: 4vw;
    font-family: 'Atma';
    font-style: normal;
    font-weight: 600;
    font-size: 7vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #33544E;
}

.deskcreatecourse {
    margin: auto;
    width: 70vw;
    height: 70vw;
}

.courseimg {
    width: 100vw;
    height: auto;
    background: #DBFFF8;
}

.create-course {
    width: 100vw;
    height: auto;
}

.searching {
    background: linear-gradient(180deg, #DBFFF8 0%, #FFFFFF 100%);
    height: 23vw;
    width: 100vw;
}

.searching h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 4vw;
    padding: 4vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #33544E;
}
.hours45{
    color: #3EAE9A;
}

.bold {
    font-weight: bold;
}

.four-cards-course {
    background: #FFFFFF;
    height: auto;

}

.two-cards-course {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-course {
    border: 3px solid #33544E;
    border-radius: 45px;
    box-shadow: 0px 0px 5px 15px #B4EFE3;
    display: inline-block;
    box-sizing: border-box;
    margin: 3vw;
    padding-top: 2vw;
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    width: 23vw;
    height: 19vw;
}

.paragraph-course {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 550;
    font-size: 1.1vw;
    line-height: 150%;
    text-align: center;
    color: #456E66;
    padding: 0.5vw;
    margin: 0.5vw;
}


.cardheading-course {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    padding: 0.4vw;
    line-height: 150%;
    text-align: center;
    color: #33544E;
}
.dotlinecourse {
    /* padding: 1vw; */
    height: 0.4vw;
    width: 17vw;
}

.tackles-course {
    background: #FFFFFF;
    height: auto;
    padding-top: 4vw;
    width: 100vw;
}

.tackles-course h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 4vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #33544E
}

.boldd {
    font-weight: bold;
}

.deskcoursefig {
    margin: auto;
    width: 70vw;
    height: 70vw;

}

.coursefigimg {
    width: 100vw;
    height: auto;
    background: #FFFFFF;
}

.coursefig {
    width: 100vw;
    height: auto;
}

.last-course {
    background: linear-gradient(180deg, #FFFDFD 0%, #DBFFF8 100%);
    height: 9vw;
}