
@media screen and (min-width: 769px) and (max-width:1025px){
    .feedback-rectangle {
        display: block;
        justify-content: center;
        padding-top: 1vw;
        align-items: center;
        height: auto;
        width: auto;
    }
    .feedback-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFF0EF 100%);
        width: 100vw;
        height: 14vw;
    }
    .feedback-headding h1 {
        padding-top: 1vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 12vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
    }
    .mobfeedb{
        display: none;
        
    }
    .deskfeedb {
        display: block;
        margin: auto;
        width: 88vw;
        height: 56vw;
    }
    .deskconsfeed{
        display: block;
        margin: auto;
        width: 78vw;
        height: 58vw;
    }
    .mobconsfeed{
        display: none;
        
    }
    .createfeedbackimg {
        text-align: center;
        height: auto;
        padding-top: 2vw;
    }
    .create-feedback {
        background: #FFF0EF;
        height: auto;
        width: 100vw;
    }
    .smarter-feedback h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
      
    }
    .smarter-feedback {
        background: linear-gradient(180deg, #FFF0EF 0%, #FFFFFF 100%);
        height: 45vw;
        width: 100vw;
        padding-top: 11vw;
    }
 .dotlineimgg-feedback {
        height: 0.4vw;
        width: 17vw;
    
}
 
.cardheadings {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 150%;
    text-align: center;
    color: #600D08;
}
   .paragra {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 550;
    font-size: 1.1vw;
    line-height: 150%;
    text-align: center;
    color: #814845;
    padding: 0.5vw;
    margin: 0.5vw;
}
    .blok {
        border: 2px solid #600D08;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 8px #FCE4E2;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 2vw;
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
       
        align-items: center;
        text-align: center;
        width: 19vw;
        height: 18vw;
    }
    .twocard {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .time-savingg {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .time-savingg h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
      
    }
    
    .three-carrds {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    
    .consolidatedd-feedback {
        text-align: center;
        height: auto;
        width: 100vw;
    }
    .consolidated-platformm-feedback {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top: 36vw;
    }
    .lastdiv {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFF0EF 100%);
        height: 10vw;
        width: 100vw;
    }
}
@media screen and (min-width: 426px) and (max-width: 769px){
    .feedback-rectangle {
        display: block;
        justify-content: center;
        padding-top: 1vw;
        align-items: center;
        height: auto;
        width: auto;
    }
    .feedback-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFF0EF 100%);
        width: 100vw;
        height: 14vw;
    }
    .feedback-headding h1 {
        padding-top: 1vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 12vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
    }
    .mobfeedb{
        display: none;
        
    }
    .deskfeedb {
        display: block;
        margin: auto;
        width: 88vw;
        height: 56vw;
    }
    .deskconsfeed{
        display: block;
        margin: auto;
        width: 78vw;
        height: 58vw;
    }
    .mobconsfeed{
        display: none;
        
    }
    .createfeedbackimg {
        text-align: center;
        height: auto;
        padding-top: 2vw;
    }
    .create-feedback {
        background: #FFF0EF;
        height: auto;
        width: 100vw;
    }
    .smarter-feedback h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
      
    }
    .smarter-feedback {
        background: linear-gradient(180deg, #FFF0EF 0%, #FFFFFF 100%);
        height: 45vw;
        width: 100vw;
        padding-top: 11vw;
    }
 .dotlineimgg-feedback {
        height: 0.4vw;
        width: 17vw;
    
}
 
.cardheadings {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 150%;
    text-align: center;
    color: #600D08;
}
   .paragra {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 550;
    font-size: 1.1vw;
    line-height: 150%;
    text-align: center;
    color: #814845;
    padding: 0.5vw;
    margin: 0.5vw;
}
    .blok {
        border: 2px solid #600D08;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 8px #FCE4E2;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 2vw;
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
       
        align-items: center;
        text-align: center;
        width: 19vw;
        height: 18vw;
    }
    .twocard {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .time-savingg {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .time-savingg h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
      
    }
    
    .three-carrds {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    
    .consolidatedd-feedback {
        text-align: center;
        height: auto;
        width: 100vw;
    }
    .consolidated-platformm-feedback {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top: 36vw;
    }
    .lastdiv {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFF0EF 100%);
        height: 10vw;
        width: 100vw;
    }
}

@media screen and (min-width: 377px) and (max-width: 426px){
    .feedback-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
        width: 100vw;
    }
    .feedback-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFF0EF 100%);
        width: 100vw;
        height: 36vw;
    }
    .feedback-headding h1 {
        padding-top: 7vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 12vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
        /* height: 80px; */
    }
    .mobfeedb{
        display: block;
        margin: auto;
    
    }
    .deskfeedb{
        display: none;
    }
    .deskconsfeed{
        display: none;
    }
    .mobconsfeed{
        display: block;
        display: block;
        margin: auto;
        
    }
    .createfeedbackimg {
        text-align: center;
        height: auto;
        padding-top: 4vw;
    }
    .create-feedback {
        background: #FFF0EF;
        height: auto;
        width: 100vw;
    }
    .smarter-feedback h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
        /* height: 45px; */
    }
    .smarter-feedback {
        background: linear-gradient(180deg, #FFF0EF 0%, #FFFFFF 100%);
        /*height: 31vw;*/
        width: 100vw;
       /* padding-top: 11vw;*/
    }
    
    .blok {
        border: 2px solid #600D08;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #FCE4E2;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        padding-top: 1vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 60vw;
        height: 61vw;
        margin-bottom: 4vw;
    }
    .cardheadings {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #600D08;
    }
    .paragra {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #814845;
        padding: 1vw;
        margin: 1vw;
    }
    img.dotlineimgg-feedback {
        width: 36vw;
        height: 2vw;
    }
    .twocard {
        display: block;
        justify-content: center;
        align-items: center;
    }
    .time-savingg {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .time-savingg h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
        /* height: 60px; */
        /* padding-top: 20px; */
    }
    
    .three-carrds {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    
    .consolidatedd-feedback {
        text-align: center;
        height: auto;
        width: 100vw;
    }
    .consolidated-platformm-feedback {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top: 16vw;
    }
    .lastdiv {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFF0EF 100%);
        height: 170px;
        width: 100vw;
    }
} 

@media (min-width: 321px) and (max-width: 376px){
    .feedback-rectangle {
    display: block;
    justify-content: center;
    padding-top: 10vw;
    align-items: center;
    height: auto;
    width: 100vw;
}
.feedback-headding {
    margin-top: 10%;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFF0EF 100%);
    width: 100vw;
    height: 36vw;
}
.feedback-headding h1 {
    padding-top: 7vw;
    font-family: 'Atma';
    font-style: normal;
    font-weight: 600;
    font-size: 12vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #600D08;
    /* height: 80px; */
}
.mobfeedb{
    display: block;
    margin: auto;

}
.deskfeedb{
    display: none;
}
.deskconsfeed{
    display: none;
}
.mobconsfeed{
    display: block;
    display: block;
    margin: auto;
    
}
.createfeedbackimg {
    text-align: center;
    height: auto;
    padding-top: 4vw;
}
.create-feedback {
    background: #FFF0EF;
    height: auto;
    width: 100vw;
}
.smarter-feedback h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 8vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #600D08;
    /* height: 45px; */
}
.smarter-feedback {
    background: linear-gradient(180deg, #FFF0EF 0%, #FFFFFF 100%);
    /*height: 31vw;*/
    width: 100vw;
    /*padding-top: 11vw;*/
}

.blok {
    border: 2px solid #600D08;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 10px #FCE4E2;
    display: inline-block;
    box-sizing: border-box;
    margin: auto;
    padding-top: 1vw;
    margin-top: 7vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    width: 60vw;
    height: 61vw;
    margin-bottom: 4vw;
}
.cardheadings {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 5vw;
    line-height: 150%;
    text-align: center;
    color: #600D08;
}
.paragra {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 4vw;
    line-height: 150%;
    text-align: center;
    color: #814845;
    padding: 1vw;
    margin: 1vw;
}
img.dotlineimgg-feedback {
    width: 36vw;
    height: 2vw;
}
.twocard {
    display: block;
    justify-content: center;
    align-items: center;
}
.time-savingg {
    background: #FFFFFF;
    height: auto;
    width: 100vw;
}
.time-savingg h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 7vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #600D08;
    /* height: 60px; */
    /* padding-top: 20px; */
}

.three-carrds {
    background: #FFFFFF;
    height: auto;
    width: 100vw;
}

.consolidatedd-feedback {
    text-align: center;
    height: auto;
    width: 100vw;
}
.consolidated-platformm-feedback {
    background: #FFFFFF;
    height: auto;
    width: 100vw;
    padding-top: 16vw;
}
.lastdiv {
    background: linear-gradient(180deg, #FFFDFD 0%, #FFF0EF 100%);
    height: 170px;
    width: 100vw;
}

}




@media screen and (max-width: 320px){

    .feedback-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }
    .feedback-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFF0EF 100%);
        height: 4vw;
    }
    .feedback-headding h1 {
        padding-top: 20px;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
        /* height: 80px; */
    }
    .createfeedbackimg img {
        width: 68vw;
        height: 108vw;
        margin: auto;
    }
    .createfeedbackimg {
        text-align: center;
        height: auto;
        padding-top: 30px;
        width: 100vw;
    }
    .create-feedback {
        background: #FFF0EF;
        height: auto;
        width: 100vw;
    }

    .smarter-feedback {
        background: linear-gradient(180deg, #FFF0EF 0%, #FFFFFF 100%);
        height: 31vw;
        width: 100vw;
        padding: 11vw;
    }

    .smarter-feedback h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
        /* height: 50px; */
    }

    .three-carrds {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .blok {
        border: 2px solid #600D08;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #FCE4E2;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        padding-top: 1vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 60vw;
        height: 61vw;
        margin-bottom: 4vw;
    }
    .cardheadings {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #600D08;
    }
    .paragra {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #814845;
        padding: 1vw;
        margin: 1vw;
    }
    img.dotlineimgg-feedback {
        width: 36vw;
        height: 2vw;
    }
    .time-savingg {
        background: #FFFFFF;
        height: 6vw;
        width: 100vw;
    }
    .time-savingg h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #600D08;
        /* height: 60px; */
        padding-top: 5vw;
    }
    .cfimg {
        height: 96vw;
        width: 85vw;
    }
    .consolidated-platformm-feedback {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top: 16vw;
    }
    .consolidatedd-feedback {
        text-align: center;
        height: auto;
        width: 100vw;
    }
    .lastdiv {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFF0EF 100%);
        height: 170px;
        width: 100vw;
    }
    .deskfeedb{
        display: none;
    }
    .mobfeedb{
        display: block;
    }
    .deskconsfeed{
        display: none;
    }
    .mobconsfeed{
        display: block;
        margin: auto;
        width: 80vw;
        height: 129vw;
    }
}


 