 @media screen and (min-width: 769px) and (max-width:1025px){
    .deskquizcons{
        display: block;
    }
    .mobquizcons{
        display: none;
    }
    .deskquiz{
        display: block;
    }
    .mobcreatequiz{
        display: none;
    }

    .quiz-rectangle {
        display: block;
        justify-content: center;
        padding-top: 1vw;
        align-items: center;
        height: auto;
    }

    .quiz-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E8DFF5 100%, #DBFFF8 100%);
        height: 9vw;
        width: 100vw;
    }

    .quiz-hd h1 {
        padding-top: 1vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
    }

    .deskquiz {
        display: block;
        width: 80vw;
        height: 100vw;
        margin: auto;
    }

    .create-quiz {
        background: #E8DFF5;
        height: auto;
        width: 100vw;
    }

    .quizimg {
        text-align: center;
        height: auto;
        padding-top: 2vw;
        width: 100vw;
    }

    .simplify h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
       
    }

    .simplify {
        background: linear-gradient(180deg, #E8DFF5 0%, #FFFFFF 100%);
        height: auto;
        width: 100vw;
        padding: 1vw;
    }


    .bloc {
        border: 2px solid #D0C6DE;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#D0C6DE;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 2vw;
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 19vw;
        height: 18vw;
        margin-bottom: 2vw;
    }
    .cardheading {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4vw;
        line-height: 150%;
        text-align: center;
        color: #373140;
    }
    .paragr-quiz {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 1.1vw;
        line-height: 150%;
        text-align: center;
        color: #60586D;
        padding: 0.5vw;
        margin: 0.5vw;
    }

    .dotlineimg {
    
        width: 17vw;
        height: 0.4vw;
    }
    .four-cards {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twos {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .tackles h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 6vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
       
    }

    .tackles {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }

    .deskquizcons {
        display: block;
        width: 88vw;
        height: 92vw;
        margin: auto;
    }

    .deliverimg {
        text-align: center;
        height: auto;
        width: 100vw;
    }

    .deliver-content {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
     
    }

    .last {
        background: linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%);
        height: 5vw;
        width: 100vw;
    }
} 

@media screen and (min-width: 426px) and (max-width: 769px){
    .deskquizcons{
        display: block;
    }
    .mobquizcons{
        display: none;
    }
    .deskquiz{
        display: block;
    }
    .mobcreatequiz{
        display: none;
    }

    .quiz-rectangle {
        display: block;
        justify-content: center;
        padding-top: 1vw;
        align-items: center;
        height: auto;
    }

    .quiz-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E8DFF5 100%, #DBFFF8 100%);
        height: 9vw;
        width: 100vw;
    }

    .quiz-hd h1 {
        padding-top: 1vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
    }

    .deskquiz {
        display: block;
        width: 80vw;
        height: 100vw;
        margin: auto;
    }

    .create-quiz {
        background: #E8DFF5;
        height: auto;
        width: 100vw;
    }

    .quizimg {
        text-align: center;
        height: auto;
        padding-top: 2vw;
        width: 100vw;
    }

    .simplify h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
       
    }

    .simplify {
        background: linear-gradient(180deg, #E8DFF5 0%, #FFFFFF 100%);
        height: auto;
        width: 100vw;
        padding: 1vw;
    }


    .bloc {
        border: 2px solid #D0C6DE;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#D0C6DE;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 2vw;
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 19vw;
        height: 18vw;
        margin-bottom: 2vw;
    }
    .cardheading {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4vw;
        line-height: 150%;
        text-align: center;
        color: #373140;
    }
    .paragr-quiz {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 1.1vw;
        line-height: 150%;
        text-align: center;
        color: #60586D;
        padding: 0.5vw;
        margin: 0.5vw;
    }

    .dotlineimg {
    
        width: 17vw;
        height: 0.4vw;
    }
    .four-cards {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twos {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .tackles h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 6vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
       
    }

    .tackles {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }

    .deskquizcons {
        display: block;
        width: 88vw;
        height: 92vw;
        margin: auto;
    }

    .deliverimg {
        text-align: center;
        height: auto;
        width: 100vw;
    }

    .deliver-content {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
     
    }

    .last {
        background: linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%);
        height: 5vw;
        width: 100vw;
    }
}

@media screen and (min-width: 377px) and (max-width: 426px){
    .deskquizcons{
        display: none;
    }
    .mobquizcons{
        display: block;
    }
    .deskquiz{
        display: none;
    }
    .mobcreatequiz{
        display: block;
    }

    .quiz-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .quiz-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E8DFF5 100%, #DBFFF8 100%);
        height: 33vw;
        width: 100vw;
    }

    .quiz-hd h1 {
        padding-top: 5vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 14vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 80px; */
    }

    .mobcreatequiz {
        display: block;
        width: 80vw;
        height: 100vw;
        margin: auto;
    }

    .create-quiz {
        background: #E8DFF5;
        height: auto;
        width: 100vw;
    }

    .quizimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .simplify h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        padding-bottom: 23vw;
        /* height: 50px; */
    }

    .simplify {
        background: linear-gradient(180deg, #E8DFF5 0%, #FFFFFF 100%);
        height: auto;
        width: 100vw;
        padding: 15vw;
    }


    .bloc {
        border: 2px solid #D0C6DE;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#D0C6DE;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        padding-top: 2vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 58vw;
        height: 58vw;
        margin-bottom: 9vw;
    }
    .cardheading {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #373140;
    }
    .paragr-quiz {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #60586D;
        padding: 1vw;
        margin: 1vw;
    }

    .dotlineimg {
        /* padding: 10px; */
        width: 50vw;
        height: 2vw;
    }
    .four-cards {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twos {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .tackles h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 60px; */
        /* padding-top: 20px; */
    }

    .tackles {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }

    .mobquizcons {
        display: block;
        width: 88vw;
        height: 92vw;
        margin: auto;
    }

    .deliverimg {
        text-align: center;
        height: auto;
        width: 100vw;
    }

    .deliver-content {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top:12vw;
    }

    .last {
        background: linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%);
        height: 170px;
        width: 100vw;
    }
}

@media (min-width: 321px) and (max-width: 376px){
    .deskquizcons{
        display: none;
    }
    .mobquizcons{
        display: block;
    }
    .deskquiz{
        display: none;
    }
    .mobcreatequiz{
        display: block;
    }

    .quiz-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .quiz-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E8DFF5 100%, #DBFFF8 100%);
        height: 33vw;
        width: 100vw;
    }

    .quiz-hd h1 {
        padding-top: 5vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 14vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 80px; */
    }

    .mobcreatequiz {
        display: block;
        width: 80vw;
        height: 100vw;
        margin: auto;
    }

    .create-quiz {
        background: #E8DFF5;
        height: auto;
        width: 100vw;
    }

    .quizimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .simplify h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 50px; */
    }

    .simplify {
        background: linear-gradient(180deg, #E8DFF5 0%, #FFFFFF 100%);
        height: auto;
        width: 100vw;
        padding: 15vw;
    }



    .bloc {
        border: 2px solid #D0C6DE;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#D0C6DE;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        padding-top: 2vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 58vw;
        height: 58vw;
        margin-bottom: 9vw;
    }
    .cardheading {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #373140;
    }
    .paragr-quiz {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #60586D;
        padding: 1vw;
        margin: 1vw;
    }

    .dotlineimg {
        /* padding: 10px; */
        width: 50vw;
        height: 2vw;
    }
    .four-cards {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twos {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .tackles h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 60px; */
        /* padding-top: 20px; */
    }

    .tackles {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }

    .mobquizcons {
        display: block;
        width: 88vw;
        height: 92vw;
        margin: auto;
    }

    .deliverimg {
        text-align: center;
        height: auto;
        width: 100vw;
    }

    .deliver-content {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top:12vw;
    }

    .last {
        background: linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%);
        height: 170px;
        width: 100vw;
    }
}

@media screen and (max-width: 320px){
    .deskquizcons{
        display: none;
    }
    .mobquizcons{
        display: block;
    }
    .deskquiz{
        display: none;
    }
    .mobcreatequiz{
        display: block;
    }

    .quiz-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .quiz-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #E8DFF5 100%, #DBFFF8 100%);
        height: 33vw;
        width: 100vw;
    }

    .quiz-hd h1 {
        padding-top: 5vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 14vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 80px; */
    }

    .mobcreatequiz {
        display: block;
        width: 80vw;
        height: 100vw;
        margin: auto;
    }

    .create-quiz {
        background: #E8DFF5;
        height: auto;
        width: 100vw;
    }

    .quizimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .simplify h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        padding-bottom: 23vw;
        /* height: 50px; */
    }

    .simplify {
        background: linear-gradient(180deg, #E8DFF5 0%, #FFFFFF 100%);
        height: auto;
        width: 100vw;
        padding: 14vw;
    }



    .bloc {
        border: 2px solid #D0C6DE;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#D0C6DE;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        padding-top: 2vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 58vw;
        height: 58vw;
        margin-bottom: 9vw;
    }
    .cardheading {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #373140;
    }
    .paragr-quiz {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #60586D;
        padding: 1vw;
        margin: 1vw;
    }

    .dotlineimg {
        /* padding: 10px; */
        width: 50vw;
        height: 2vw;
    }
    .four-cards {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twos {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .tackles h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #373140;
        /* height: 60px; */
        /* padding-top: 20px; */
    }

    .tackles {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }

    .mobquizcons {
        display: block;
        width: 88vw;
        height: 92vw;
        margin: auto;
    }

    .deliverimg {
        text-align: center;
        height: auto;
        width: 100vw;
    }

    .deliver-content {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
        padding-top:12vw;
    }

    .last {
        background: linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%);
        height: 170px;
        width: 100vw;
    }

}