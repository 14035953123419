  @import url('https://fonts.googleapis.com/css2?family=Atma&family=Lato:wght@400;700&display=swap');


  .Main-PricingPage-Conatiner {
      width: 100vw;
      height: auto;
      padding-top: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .PricingPage {
      width: 100vw;
      height: auto;
      padding-top: 10vh;
  }

  .top-section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .top-inner-section {
      width: 50%;
      /* border: 2px solid black; */
      text-align: center;
  }

  .pricingText-section h2 {
      font-family: Atma;
      font-size: 95px;
      font-weight: 600;
      line-height: 143px;
      letter-spacing: 0em;
      text-align: center;
      color: #065849;
  }

  .pricingText-section p {
      font-family: Lato;
      font-size: 35px;
      font-weight: 400;
      line-height: 53px;
      letter-spacing: 0em;
      text-align: center;
      color: #065849;
  }

  .Pricing-container {
      width: 100vw;
      height: auto;
      position: relative;
      /* border: 2px solid yellow; */
  }

  .paperAnimation {
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .switch-btn {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .switch-btn-inner {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5rem;
      position: relative;
      top: -12px;
  }

  .switch-btn-main {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 2.5vw;
      height: 9vh;
      border-radius: 23px;
      background: #DCF3EF;
      box-shadow: 0px 0px 8px 0px #B7B7B7;
  }

  .switch-btn #Quarterly,
  .switch-btn #Yearly {
      width: 100%;
      height: 8vh;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 22px;
      margin-top: 0.9rem;
      padding-top: 1rem;
      margin-left: 0.2rem;
      color: #708F8A;
  }

  .switch-btn #Quarterly:hover,
  .switch-btn #Yearly:hover {
      cursor: pointer;
  }

  .commonClass-switchbtn {
      background: #FFFFFF !important;
      font-weight: 600 !important;
      color: #065849 !important;
  }

  /* .form-switch {
      width: 4em;
      height: 2em;
      position: relative;
      background-color: #D3E1E6 !important;
      border-radius: 2em;
  }

  .form-switch .form-check-input {
      width: 5em;
      height: 3em;
      background-image: url("../Images/switch.svg") !important;
      background-position: left 4px;
      background-color: transparent !important;
      box-shadow: none !important;
      float: none !important;
      position: absolute;
      top: -15px;
      left: 40px;
      z-index: 99;
      border: none !important;
  }

  .form-check-input:checked {
      border-color: "none" !important;
      background-position: right 4px !important;
  }

 */

  /* Card Style */
  /* .PricingCard {
    width: 100vw;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
} */

  .backgrounImage {
      width: 100vw;
      height: 15vh;
      margin: 8vw 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
  }

  .backgrounImage .getFreebtn {
      height: 89px;
      width: 503px;
      position: absolute;
      left: 38%;
      border-radius: 44px;
      background: #F2FCFF;
      border: none;
      font-family: 'Jost';
      font-size: 30px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: center;
      text-decoration-line: underline;
      color: #1F5262;
      /* box-shadow: 0px 4px 12px #C3EBF7; */
  }



  .PricingCards {
      width: 100vw;
      padding-top: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
  }

  .PricingCard_inner {
      width: 60vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 8vw;
  }

  .pricingCard-content {
      width: 360px;
      height: 417px;
      position: relative;
  }

  .pricing-content {
      width: 100%;
      height: 417px;
      display: flex;
      justify-content: center;
      border-Radius: 45px;
  }

  .pricing-inner-content {
      width: 90%;
      height: auto;
      padding-top: 2rem;
      text-align: center;
      color: #1F5262;
  }

  .pricing-inner-content h1 {
      font-family: 'Atma';
      /* text-indent: 2px; */
      font-size: 30px;
      letter-spacing: 0em;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
  }

  .pricing-inner-content p {
      margin-bottom: 0 !important;
      font-family: 'Lato';
      font-size: 20px;
      font-weight: 500;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: center;
  }

  .pricing-inner-content p .amount {
      font-family: 'Jost';
      font-size: 30px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: center;
  }

  .buybtn {
      height: 37.7px;
      width: 132.73px;
      border-radius: 4px;
      font-family: 'Raleway';
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
      border-radius: 4px;
      border: none;
      background-color: #845DA2;
      outline: none;
      /* box-shadow: -3px -3px 0px 0px #845DA2; */
      /* transform: skew(-18deg); */
  }

  #blueprint:hover {
      box-shadow: 0px 0px 8px #1F5262;
  }

  #Prototype:hover {
      box-shadow: 0px 0px 8px #4E490F;
  }

  #Model:hover {
      box-shadow: 0px 0px 8px #373140;

  }

  #Flying:hover {
      box-shadow: 0px 0px 8px #55120F;
  }

  .PricingCard_inner-mobile {
      display: none;
  }


  @media screen and (max-width:768px) {

      .PricingCard_inner-mobile {
          display: block;
      }

      .backgrounImage .getFreebtn {
          height: 89px;
          width: 300px;
          border-radius: 44px;
          background: #F2FCFF;
          border: none;
          font-family: 'Jost';
          font-size: 25px;
          font-weight: 800;
          line-height: 45px;
          letter-spacing: 0em;
          text-align: center;
          text-decoration-line: underline;
          color: #1F5262;
      }

      .switch-btn p {
          font-size: 15px !important;
      }

      .switch-btn-main {
          column-gap: 4vw !important;
      }
  }

  @media (min-width:375px) and (max-width:430px) {
      .PricingCard_inner {
          display: none;
      }

      .PricingCard_inner-mobile {
          width: 90vw;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 2vw;
      }

      .pricingCard-content {
          position: relative;
          left: 14%;
      }

      .form-switch .form-check-input {
          top: -13px !important;
          left: 30px !important;
      }

      .pricingText-section p {
          font-size: 15px;
          font-weight: 400;
      }
  }

  @media screen and (max-width:376px) {
      .PricingCard_inner {
          display: none;
      }

      .PricingCard_inner-mobile {
          width: 90vw;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 2vw;
          margin-top: 5%;
      }

      .pricingCard-content {
          position: relative;
          left: 5%;
      }

      .form-switch .form-check-input {
          top: -13px !important;
          left: 30px !important;
      }

      .pricingText-section p {
          font-size: 15px;
          font-weight: 400;
      }
  }