@media screen and (min-width: 769px) and (max-width:1025px){}
@media screen and (min-width: 426px) and (max-width: 769px){}
@media screen and (min-width: 377px) and (max-width: 426px){
.course-rectangle {
    display: block;
    justify-content: center;
    padding-top: 10vw;
    align-items: center;
    height: auto;
}

.mobcoursefig {
    display: block;
}

.mobcreatecourse {
    display: block;
}

.deskcreatecourse {
    display: none;
}

.deskcoursefig {
    display: none;
}

.course-head {
    margin-top: 10%;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);
    height: 23vw;
    width: 100vw;
}

.course-head h1 {
    padding-top: 4vw;
    font-family: 'Atma';
    font-style: normal;
    font-weight: 600;
    font-size: 7vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #33544E;
}

.mobcreatecourse {
    margin: auto;
    width: 70vw;
    height: 70vw;
}

.courseimg {
    width: 100vw;
    height: auto;
    background: #DBFFF8;
}

.create-course {
    width: 100vw;
    height: auto;
}

.searching {
    background: linear-gradient(180deg, #DBFFF8 0%, #FFFFFF 100%);
    height: 23vw;
    width: 100vw;
}

.searching h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 6vw;
    padding: 2vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #33544E;
}

.bold {
    font-weight: bold;
}

.four-cards-course {
    background: #FFFFFF;
    height: auto;
}

.two-cards-course {
    display: block;
    justify-content: center;
    align-items: center;
}

.block-course {
    border: 2px solid #33544E;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 10px #B4EFE3;
    display: inline-block;
    box-sizing: border-box;
    margin: auto;
    padding-top: 3vw;
    margin-top: 12vw;
    margin-bottom: 4vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    width: 55vw;
    height: 50vw;
}

.paragraph-course {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    line-height: 150%;
    text-align: center;
    color: #456E66;
    padding: 1vw;
    margin: 1vw;
}


.cardheading-course {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 4vw;
    line-height: 150%;
    text-align: center;
    color: #33544E;
}
.dotlinecourse {
    padding: 1vw;
    height: 4vw;
    width: 49vw;
}

.tackles-course {
    background: #FFFFFF;
    height: auto;
    padding-top: 10vw;
    width: 100vw;
}

.tackles-course h1 {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 7vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #33544E;
    padding: 3vw;
}

.boldd {
    font-weight: bold;
}

.mobcoursefig {
    margin: auto;
    width: 100vw;
    height: 113vw;
}

.coursefigimg {
    width: 100vw;
    height: auto;
    background: #FFFFFF;
}

.coursefig {
    width: 100vw;
    height: auto;
}

.last-course {
    background: linear-gradient(180deg, #FFFDFD 0%, #DBFFF8 100%);
    height: 23vw;
}}
@media (min-width: 321px) and (max-width: 376px){
    .course-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .mobcoursefig {
        display: block;
    }

    .mobcreatecourse {
        display: block;
    }

    .deskcreatecourse {
        display: none;
    }

    .deskcoursefig {
        display: none;
    }

    .course-head {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);
        height: 23vw;
        width: 100vw;
    }

    .course-head h1 {
        padding-top: 4vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #33544E;
    }

    .mobcreatecourse {
        margin: auto;
        width: 70vw;
        height: 70vw;
    }

    .courseimg {
        width: 100vw;
        height: auto;
        background: #DBFFF8;
    }

    .create-course {
        width: 100vw;
        height: auto;
    }

    .searching {
        background: linear-gradient(180deg, #DBFFF8 0%, #FFFFFF 100%);
        height: 23vw;
        width: 100vw;
    }

    .searching h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 6vw;
        padding: 2vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #33544E;
    }

    .bold {
        font-weight: bold;
    }

    .four-cards-course {
        background: #FFFFFF;
        height: auto;
    }

    .two-cards-course {
        display: block;
        justify-content: center;
        align-items: center;
    }

 
    .block-course {
        border: 2px solid #33544E;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #B4EFE3;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        padding-top: 3vw;
        margin-top: 12vw;
        margin-bottom: 4vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 66vw;
        height: 59vw;
    }

   
    .paragraph-course {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #456E66;
        padding: 1vw;
        margin: 1vw;
    }


    .cardheading-course {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #33544E;
    }
    .dotlinecourse {
        padding: 1vw;
        height: 4vw;
        width: 56vw;
    }

    .tackles-course {
        background: #FFFFFF;
        height: auto;
        padding-top: 10vw;
        width: 100vw;
    }

    .tackles-course h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #33544E;
        padding: 3vw;
    }

    .boldd {
        font-weight: bold;
    }

    .mobcoursefig {
        margin: auto;
        width: 100vw;
        height: 113vw;
    }

    .coursefigimg {
        width: 100vw;
        height: auto;
        background: #FFFFFF;
    }

    .coursefig {
        width: 100vw;
        height: auto;
    }

    .last-course {
        background: linear-gradient(180deg, #FFFDFD 0%, #DBFFF8 100%);
        height: 23vw;
    }
}

@media screen and (max-width: 320px){
    .course-rectangle {
            display: block;
            justify-content: center;
            padding-top: 10vw;
            align-items: center;
            height: auto;
        }
    
        .mobcoursefig {
            display: block;
        }
    
        .mobcreatecourse {
            display: block;
        }
    
        .deskcreatecourse {
            display: none;
        }
    
        .deskcoursefig {
            display: none;
        }
    
        .course-head {
            margin-top: 10%;
            background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);
            height: 23vw;
            width: 100vw;
        }
    
        .course-head h1 {
            padding-top: 4vw;
            font-family: 'Atma';
            font-style: normal;
            font-weight: 600;
            font-size: 7vw;
            line-height: 150%;
            align-items: center;
            text-align: center;
            color: #33544E;
        }
    
        .mobcreatecourse {
            margin: auto;
            width: 70vw;
            height: 70vw;
        }
    
        .courseimg {
            width: 100vw;
            height: auto;
            background: #DBFFF8;
        }
    
        .create-course {
            width: 100vw;
            height: auto;
        }
    
        .searching {
            background: linear-gradient(180deg, #DBFFF8 0%, #FFFFFF 100%);
            height: 23vw;
            width: 100vw;
        }
    
        .searching h1 {
            font-family: 'Atma';
            font-style: normal;
            font-weight: 400;
            font-size: 6vw;
            padding: 2vw;
            line-height: 150%;
            align-items: center;
            text-align: center;
            color: #33544E;
        }
    
        .bold {
            font-weight: bold;
        }
    
        .four-cards-course {
            background: #FFFFFF;
            height: auto;
        }
    
        .two-cards-course {
            display: block;
            justify-content: center;
            align-items: center;
        }
    
        .block-course {
            border: 2px solid #33544E;
            border-radius: 20px;
            box-shadow: 0px 0px 5px 10px #B4EFE3;
            display: inline-block;
            box-sizing: border-box;
            margin: auto;
            padding-top: 3vw;
            margin-top: 12vw;
            margin-bottom: 4vw;
            display: flex;
            flex-direction: column;
            /* justify-content: center; */
            align-items: center;
            text-align: center;
            width: 66vw;
            height: 62vw;
        }
    
        .paragraph-course {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 4vw;
            line-height: 150%;
            text-align: center;
            color: #456E66;
            padding: 1vw;
            margin: 1vw;
        }
    
    
        .cardheading-course {
            font-family: 'Atma';
            font-style: normal;
            font-weight: 500;
            font-size: 5vw;
            line-height: 150%;
            text-align: center;
            color: #33544E;
        }
        .dotlinecourse {
            padding: 1vw;
            height: 4vw;
            width: 54vw;
        }
    
        .tackles-course {
            background: #FFFFFF;
            height: auto;
            padding-top: 10vw;
            width: 100vw;
        }
    
        .tackles-course h1 {
            font-family: 'Atma';
            font-style: normal;
            font-weight: 400;
            font-size: 7vw;
            line-height: 150%;
            align-items: center;
            text-align: center;
            color: #33544E;
            padding: 3vw;
        }
        .boldd {
            font-weight: bold;
        }
    
        .mobcoursefig {
            margin: auto;
            width: 100vw;
            height: 113vw;
        }
    
        .coursefigimg {
            width: 100vw;
            height: auto;
            background: #FFFFFF;
        }
    
        .coursefig {
            width: 100vw;
            height: auto;
        }
    
        .last-course {
            background: linear-gradient(180deg, #FFFDFD 0%, #DBFFF8 100%);
            height: 23vw;
        }
    }