.blog-container {

  .blogPosts-wapper {
    font-weight: 700;
    margin-bottom: 25px;
    font-family: "Atma";
    color: #44B0C8;
    opacity: 0.6;

  }

  .blogPosts-wapper::after {
    content: '';
    display: block;
    width: 20%;
    height: 2px;
    border-bottom: 5px solid #44B0C8;
    position: relative;
    bottom: 0;

  }

  .MuiCard-root {
    position: relative;
    transition: all 1s ease-in-out;
  }

  .MuiCard-root::after {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background: transparent;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    transition: all 1s ease-in-out;
  }

  .MuiCard-root:hover.MuiCard-root::after {
    width: 100%;
    background: #44B0C8;
    transition: width all 0.1ms ease-in-out;
  }

  .screen-reader-text,
  .read-more {
    display: none !important;
  }

  .read-more-btn {
    background-color: #44B0C8 !important;
    position: absolute !important;
    top: 40% !important;
    left: 25% !important;
    padding: 0.4rem 0.9rem !important;
    display: none !important;
    color: #ffff !important;
    z-index: 12;
  }

  .expcert-box {
    height: 200px;
    overflow: hidden;
  }

  .MuiCard-root:hover .read-more-btn {
    display: block !important;
    cursor: pointer !important;
  }

}

@media (min-width:320px) and (max-width:430px) {
  .Post-Title {
    font-size: xx-large !important;
  }
}