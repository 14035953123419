@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');

::-webkit-scrollbar {
    display: none;
}

.textBlue {
    color: #2A7086 !important;
    text-decoration: none;
}

.textWhite {
    color: white;
    text-decoration: none;
}

.textWhite:hover {
    /* text-decoration: underline; */
    color: white;
    cursor: pointer;
}

.hide {
    display: none;
}

.main-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    /* overflow-y: hidden; */
    display: flex;
    flex-direction: column;
}

.popup {
    display: none;
}

.blur {
    display: none;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* height: 6.5vw; */
    overflow: hidden;
    z-index: 998;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 0.1s ease-in-out;
    background: transparent;
}

.header-color {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* height: 6.5vw; */
    overflow: hidden;
    z-index: 2000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 0.5s ease-in-out;
    background-color: white;
    box-shadow: 0vw 0.2vw 1vw 0vw #71717140;

}

.logo {
    width: 12vw;
    /* position: relative;
    left: 10.5vw; */
}

.home {
    display: none;
}

.all-link {
    width: 70%;
    height: auto;
    display: flex;
    align-items: center;
    float: right;
    /* position: relative;
    left: 35.5vw; */
}

.all-link ul {
    width: 100%;
    color: #2D7288;
    list-style: none;
    display: flex;
    justify-content: end;
    gap: 2vw;
    font-family: Atma;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
    position: relative;
    top: 0.5vw;
    padding-right: 5%;
}

.all-link ul li .dropDown {
    width: 1vw;
    border: 2px solid red;
}

.all-link ul li .dropDown:hover {
    cursor: pointer;
}

.dropdown_rotate {
    transform: rotateX(180deg);
}

.button {
    position: relative;
    left: 0vw;
    width: 180px;
    height: 50px;
    font-family: Lato;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 50px;
    border: none;
    color: white;
    font-size: 1.2vw;
    text-align: center;
    background-color: #44B0C8;
    outline: none;
    border: 2px dashed #2D7288;
}

.button .login a {
    text-decoration: none;
    color: white;
}

.button .login a:hover {
    color: white;
}

.active {
    border-bottom: 0.15vw dashed #2A7086;
    font-weight: 600;
}

.all-link ul li span:hover {
    border-bottom: 0.18vw dashed #2A7086;
    font-weight: 500;
}

.all-link a:hover {
    color: #2D7288;
}



/* Part 1 */
.container1 {
    width: 100vw;
    height: 50vw;
    display: flex;
    flex-direction: row;
    gap: 2vw;
}

.div1 {
    position: relative;
    top: 0vw;
    left: 0vw;
    min-width: 60vw;
    height: 50vw;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    border-radius: 0 4vw 8vw 0;
    box-shadow: -18px 12px 72px rgba(45, 114, 136, 0.8);
    transform-origin: bottom right;
    transform: skew(20deg);
    overflow: hidden;
}

.bg1 {
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    background: url(./Images/line_image_gradient_svg.svg);
    background-size: 150vw 80vw;
    background-repeat: no-repeat;
    background-position: top -8vw left -35vw;
    min-width: 73.5vw;
    height: 60vw;
    transform: skew(-20deg);
    overflow: hidden;
}

.text1 {
    position: relative;
    top: 18vw;
    left: 18vw;
    width: 35vw;
    height: 8vw;
    color: #1F5262;
    font-weight: 800;
    font-size: 3vw;
    line-height: 4vw;
}

.text2 {
    position: relative;
    top: 19vw;
    left: 18vw;
    width: 33vw;
    height: 6vw;
    font-size: 1.4vw;
    font-family: "Jost", sans-serif;
    color: #2D7288;
    font-weight: 500;
    line-height: 2vw;
}

.div1 .button {
    position: relative;
    top: 22vw;
    left: 18vw;
    width: 10vw;
    height: 3vw;
    font-family: "Raleway", sans-serif;
    font-weight: 200;
    font-size: 1.1vw;
    font-weight: 500;
    color: white;
    background: #2D7288;
    border-radius: 0.4vw;
    border: none;
    /* transform: skew(-10deg); */
    outline: none;
}

.div1 .button a {
    color: white;
    text-decoration: none;
}

.div1 .button img {
    width: 1vw;
}

.div2 {
    position: relative;
    top: 0vw;
    left: -8vw;
    min-width: 60vw;
    height: 50vw;
    border-radius: 8vw 0 0 4vw;
    transform: skew(20deg);
    overflow: hidden;
    z-index: 4;
}

.bg2 {
    position: fixed;
    left: -10vw;
    background: linear-gradient(rgba(248, 255, 254, 0.8), rgba(65, 128, 147, 0.4)), url('http://d2s9pyuyxqivbc.cloudfront.net/home_top.png');
    background-size: 60vw 55vw;
    background-repeat: no-repeat;
    background-position: bottom -4vw left -2vw;
    min-width: 62vw;
    height: 50vw;
    transform: skew(-20deg);
    overflow: hidden;
}

.div2 .play {
    transform: skew(-20deg);
    overflow: hidden;
    position: relative;
    top: 22vw;
    left: 18.5vw;
    width: 10vw;
    height: 8vw;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 4vw;
    color: #2D7288;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.div2 .play img {
    width: 5vw;
}


/* Part 2 */
.container2 {
    overflow: hidden;
    width: 100vw;
    height: 55vw;
    display: flex;
}

.part1 {
    width: 40vw;
}

.part1 .box1 {
    width: 30vw;
    height: 30vw;
    position: relative;
    top: 13vw;
    left: -4vw;
    border-radius: 6vw;
    background: linear-gradient(124.2deg, #F8FFFE 15.24%, #2D7288 122.54%);
    box-shadow: 3px 5px 17px -2px rgba(45, 114, 136, 0.5);
    transform: rotate(61.97deg);
}

.part1 .box2 {
    width: 26vw;
    height: 30vw;
    position: relative;
    top: -17vw;
    left: 9.5vw;
    border-radius: 10vw 0;
    overflow: hidden;
}

.part1 .box2 img {
    width: 45vw;
    position: relative;
    left: -10vw;
}

.part2 {
    width: 60vw;
}

.part2 .text3 {
    position: relative;
    top: 15vw;
    left: 6.5vw;
    width: 40vw;
    height: 8vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 3.15vw;
    color: #1F5262;
    line-height: 4vw;
}

.part2 .text3 span {
    font-weight: 700;
}

.part2 .text4 {
    position: relative;
    top: 16.5vw;
    left: 6.5vw;
    width: 30.5vw;
    height: 8vw;
    line-height: 1.6vw;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 1.05vw;
    color: #465154;

}

.part2 .text5 {
    position: relative;
    top: 19vw;
    left: 6.5vw;
    width: 7vw;
    height: 2vw;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 1.5vw;
    color: #2D7288;
    text-decoration: none;
}

.part2 .arrow {
    position: relative;
    /* top: -1.2vw; */
    left: 0.5vw;
    width: 1.3vw;
    color: #2D7288;
}

.part2 .line {
    position: relative;
    top: -3vw;
    left: 19.4vw;
    z-index: -1;
    width: 13.7vw;
}

/* Part 3 */
.container3 {
    min-width: 100vw;
    height: 60vw;
    display: flex;
}

.rect1 {
    z-index: -1;
    min-width: 100vw;
    height: 60vw;
    position: relative;
    top: 0vw;
    left: -10vw;
    border-radius: 5vw;
    background-color: #EBF6F7;
}

.rect1 img {
    position: relative;
    width: 30vw;
    height: 40vw;
    top: 13vw;
    left: 5.4vw;
    transform: matrix(-0.98, 0.19, 0.19, 0.98, 0, 0);
}

.rect2 {
    z-index: 2;
    min-width: 100vw;
    height: 60vw;
    position: relative;
    top: 1.5vw;
    left: -84vw;
    background-color: #fff;
    transform: skew(-10deg);
    box-shadow: -10px 23px 33px rgba(45, 114, 136, 0.3);
    border-radius: 8vw;
    overflow: hidden;
}

.rect2 .backImg {
    display: none;
}

.rect2 .content {
    transform: skew(10deg);
    width: 100vw;
    height: 60vw;
}

.rect2 .content .text6 {
    /* background-color: red; */
    width: 35vw;
    width: -moz-max-content;
    width: -moz-available;
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    height: 4vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 3.2vw;
    align-items: center;
    position: relative;
    top: 5.2vw;
    left: 28vw;
    color: #1F5262;
    -webkit-width: 35vw;
}

.rect2 .content .text6 span {
    font-weight: 700;
}

.rect2 .content .text6 img {
    position: relative;
    top: 1vw;
    left: -11.6vw;
    width: 10vw;
    z-index: -1;
}

.rect2 .content .cards {
    position: relative;
    top: 6vw;
    left: 4vw;
    width: 78vw;
    height: 41vw;
    display: flex;
    column-gap: 4vw;
    /* row-gap: 1vw; */
    flex-wrap: wrap;
    padding: 1vw;
    padding-top: 0 !important;
    align-items: center;
    justify-content: center;
}

.all-card {
    position: relative;
    top: 1vw;
    left: 1vw;
    width: 20vw;
    height: 16vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2vw;
}

.all-card .logos {
    width: 5vw;
    height: 5vw;
}

.all-card .heading {
    text-align: center;
    min-width: 15vw;
    height: 1.3vw;
    /* background-color: red; */
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 1.1vw;
    color: #1F5262;
}

.all-card .blue-line {
    width: 2.2vw;
    color: #1F5262;
}

.all-card .text {
    width: 15vw;
    height: 3vw;
    /* background-color: red; */
    text-align: center;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 1vw;
    color: #465154;
}

.all-card .text a {
    color: #2D7288;
    text-decoration: none;
}

.see-link {
    width: 9vw;
    text-align: center;
    font-size: 1.5vw;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    position: relative;
    left: 40vw;
    top: 7.5vw;
}

.see-link a {
    color: #2D7288;
    text-decoration: none;
}

.see-link a:hover {
    color: #2D7288;
}

.see-link a img {
    margin-left: 0.5vw;
    width: 1.2vw;
}

/* Part 4 */
.part4 {
    width: 100vw;
    height: 16vw;
    overflow: hidden;
}

.part4 .text8 {
    width: 40vw;
    height: 4vw;
    position: relative;
    top: 11vw;
    left: 10vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 3.1vw;
    text-align: center;
    color: #1F5262;
}

.part4 .text8 .bold {
    font-weight: 700;
}

.part4 .line {
    z-index: -1;
    position: relative;
    width: 8vw;
    top: -2.8vw;
}

.arrow-left .left,
.arrow-right .right {
    display: none;
}

.card-container {
    /* background-color: red; */
    width: 90vw;
    min-height: 42vw;
    overflow: hidden;
    position: relative;
    left: 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6vw;
    padding-bottom: 6vw;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.card2 {
    background: linear-gradient(180deg, rgba(42, 112, 134, 0) 48.45%, #2A7086 92.71%);
    box-shadow: 0px 0px 18px rgba(229, 240, 239, 0.68);
    width: 20vw;
    height: 30vw;
    border-radius: 3vw;
    overflow: hidden;
}

.card2 .lines {
    width: 22vw;
    height: 50vw;
    position: relative;
    top: -10.2vw;
}

.card2 .k_logo {
    z-index: 10;
    position: relative;
    top: -40vw;
    left: 2.5vw;
    width: 15vw;
}

.card2 .heading {
    z-index: 10;
    position: relative;
    top: -40vw;
    left: 4.5vw;
    text-align: center;
    width: 11vw;
    height: 5.5vw;
    line-height: 2.7vw;
    font-size: 1.9vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #2A7086;
}

.card1 {
    position: relative;
    width: 15vw;
    height: 22vw;
    border-radius: 2.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
}

.card1 .demo {
    width: 15.5vw;
    min-height: 23vw;
    position: relative;
    top: -9vw;
    z-index: -2;
}

.card1 .play {
    z-index: 30;
    position: relative;
    top: -22.5vw;
    left: 4vw;
    width: 7vw;
    height: 3.5vw;
    color: #1F5262;
}

.card1 .heading {
    z-index: 30;
    color: #fff;
    font-size: 1.05vw;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    width: 15vw;
    position: relative;
    top: -16.5vw;
}

.card1 .gradient {
    position: relative;
    top: 14vw;
    z-index: 10;
    width: 15vw;
    min-height: 23vw;
    background: linear-gradient(180deg, rgba(42, 112, 134, 0) 30.45%, #2A7086 95.71%);
}

/* Part 5 */
.part5 {
    width: 100vw;
    height: 60vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('http://d2s9pyuyxqivbc.cloudfront.net/home_let_the_learning_begin.png');
    background-size: 115vw;
    background-position: top 0vw left -15vw;
}

.text7 {
    text-align: center;
    width: 46vw;
    height: 6vw;
    font-size: 3.3vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #1F5262;
    text-transform: uppercase;
}

.alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.part5 .size-76 {
    font-size: 5vw;
}

.part5 .bold {
    font-weight: 700;
}

.part5 .line {
    position: relative;
    top: -3.4vw;
    left: 15.3vw;
    width: 11.5vw;
}

/* .part5 .learn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3vw;
}

.fox_l {
    width: 22vw;
    height: 28vw;
    position: relative;
}

.part5 .build {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}

.fox_b {
    width: 34vw;
    height: 30vw;
}

.part5 .grow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.fox_g {
    width: 35vw;
    height: 35vw;
}

.img_desc {
    font-family: "Bungee Shade", sans-serif;
    font-weight: 400;
    font-size: 5.2vw;
    color: #1F5262;
} */

/* Part 6 */
.part6 {
    width: 100%;
    height: 85vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.part6 .inner-container {
    background-color: #2A2B2F;
    background-image: url(./Images/web.png);
    width: 90vw;
    height: 65vw;
    border-radius: 3vw;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
}

.inner-container .text {
    position: relative;
    top: 8vw;
    left: 27vw;
    z-index: 2;
    width: 35vw;
    height: 5vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 4vw;
    text-align: center;
}

.inner-container .text span {
    font-weight: 700;
    z-index: 2;
}

.inner-container .line {
    width: 18vw;
    position: relative;
    top: -3.5vw;
    left: -4.1vw;
    z-index: -1;
}

.inner-container .glass-cards {
    /* background-color: red; */
    width: 80vw;
    height: 40vw;
    position: relative;
    top: 13vw;
    /* top: -webkit-fill-available; */
    padding-left: 5vw;
    padding-right: 4vw;
}

.swiper-button-next::after,
.swiper-button-prev:after {
    font-size: 2.5vw !important;
    color: white;
}

.glass-card {
    width: 17vw !important;
    height: 26vw;
    border-radius: 2.5vw;
    background: linear-gradient(157.08deg,
            rgba(255, 255, 255, 0.5) 5.56%,
            rgba(255, 255, 255, 0) 47.01%,
            rgba(45, 114, 136, 0) 66.3%,
            rgba(45, 114, 136, 0.5) 97.52%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 7vw;
}

.glass-card .container {
    z-index: 100;
    width: 15vw;
    height: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.glass-card .container svg {
    width: 5vw;
    height: 5vw;
    fill: #D2D5D6;
    opacity: 80%;
    filter: drop-shadow(0.2vw -0.1vw 0.1vw rgba(211, 211, 211, 0.4));
}

.para {
    /* background-color: red; */
    width: 14vw;
    height: 3.5vw;
    font-family: "Jost", sans-serif;
    font-size: 1.1vw;
    font-weight: 300;
    color: #afafaf;
    text-align: center;
}

/* .part6 .inner-container .swiper-container {
    background-color: red;
    height: 0;
    padding: 0;
    position: relative;
    top: 5vw;
} */

.mySwiper {
    right: 0% !important;
    margin-top: 0 !important;
}

.swiper-slide-next {
    margin-right: 5vw;
}

.swiper-slide-next .glass-card {
    width: 22vw !important;
    height: 32vw !important;
    border-radius: 2.5vw;
    background: linear-gradient(157.08deg,
            rgba(255, 255, 255, 0.5) 5.56%,
            rgba(255, 255, 255, 0) 47.01%,
            rgba(45, 114, 136, 0) 66.3%,
            rgba(45, 114, 136, 0.5) 97.52%);
    display: flex;
    /* background-color: red; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    position: relative;
    top: 4vw;
}

.swiper-slide-next .glass-card .container {
    z-index: 100;
    width: 18vw;
    height: 24vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    /* background-color: red; */
}

.swiper-slide-next .glass-card svg {
    width: 7vw;
    height: 7vw;
    fill: white !important;
    opacity: 1;
    filter: drop-shadow(-0.2vw -0.1vw 0.1vw rgba(184, 215, 225, 1));
}

.swiper-slide-next .glass-card .head {
    /* background-color: red; */
    width: 20vw;
    text-align: center;
    color: white;
    text-shadow: -2px -1px 3px #A6C9D5;
    font-size: 2.2vw;
}

.swiper-slide-next .glass-card .para {
    /* background-color: pink; */
    width: 19vw;
    height: 3.5vw;
    font-family: "Jost", sans-serif;
    font-size: 1.1vw;
    font-weight: 300;
    color: #afafaf;
    text-align: center;
}

.heading1 {
    width: 15vw;
    height: 3.5vw;
    font-size: 2.5vw;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    text-align: center;
    color: #afafaf;
    letter-spacing: 0.2vw;
    line-height: 3.5vw;
    text-shadow: -0.2vw -0.1vw 0.3vw rgba(211, 211, 211, 0.6);
}

.heading2 {
    /* background-color: red; */
    width: 15vw;
    height: 3.5vw;
    font-size: 2.3vw;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    text-align: center;
    color: #afafaf;
    line-height: 3.5vw;
    text-shadow: -0.2vw -0.1vw 0.3vw rgba(211, 211, 211, 0.6);
}

.heading3 {
    /* background-color: pink; */
    width: 16vw;
    height: 3.5vw;
    font-size: 1.8vw;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    text-align: center;
    color: #afafaf;
    line-height: 3.5vw;
    text-shadow: -0.2vw -0.1vw 0.3vw rgba(211, 211, 211, 0.6);
}

/* Part 7 */
.part7 {
    width: 100vw;
    height: 60vw;
    background: linear-gradient(180deg, #FFFFFF 10%, #C4DBE0 51.59%, #448093 103.97%);
    display: flex;
}

.part7 .frame-container {
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.part7 .frame-container .circle {
    width: 40vw;
    height: 40vw;
    background-color: #dcf0f3;
    border-radius: 50vw;
}

.part7 .frame-container .frame {
    width: 42vw;
    height: 32vw;
    position: absolute;
}

.part7 .frame-container .lamination {
    position: absolute;
    width: 42vw;
    height: 31.5vw;
    background-color: rgba(235, 246, 247, 0.15);
}

.part7 .text-container {
    width: 39vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.part7 .text-container .heading {
    width: 20vw;
    height: 4vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 3.2vw;
    color: #1F5262;
    position: relative;
    top: -2vw;
}

.part7 .text-container .heading span {
    font-weight: 700;
    z-index: 3;
}

.part7 .text-container .line {
    position: relative;
    top: -2.9vw;
    left: 8.4vw;
    width: 9.6vw;
    z-index: 2;
}

.part7 .text-container .para {
    width: 26vw;
    height: 4vw;
    font-size: 1.3vw;
    font-weight: 400;
    font-family: "Jost", sans-serif;
    position: relative;
    top: -1.5vw;
    color: #333;
}

.part7 .text-container .input-container {
    position: relative;
    top: -1vw;
    width: 25vw;
    height: 3vw;
    transform: skew(-10deg);
    display: flex;
    gap: 0.5vw;
}

.part7 .text-container .input-container .input {
    width: 21vw;
    height: 3vw;
    border-radius: 0.2vw;
    background-color: white;
    padding-left: 0.4vw;
    z-index: 0;
    display: flex;
    align-items: center;
}

.part7 .text-container .input-container .input input {
    position: relative;
    width: 19.5vw;
    height: 2vw;
    border-radius: 0.2vw;
    border: none;
    font-size: 1vw;
    font-weight: 400;
    font-family: "Jost", sans-serif;
    outline: none;
    transform: skew(10deg);
    z-index: 1;
}

.part7 .text-container .input-container button {
    width: 3.1vw;
    height: 3vw;
    background-color: #2D7288;
    border: none;
    border-radius: 0.2vw;
    font-size: 1vw;
}

.part7 .text-container .input-container button .btnTransform {
    transform: skew(10deg);
}

.part7 .text-container .input-container button .arrow {
    width: 1.2vw;
}

/* Footer */
.footer-container {
    display: flex;
    flex-direction: column;
}

.footer {
    width: 100vw;
    /* height: 26vw; */
    height: auto;
    background-color: #2A2B2F;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5vw;
    text-align: justify;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 1.05vw;
    color: #fff;
    position: relative;
    /* padding-top: 4vw; */
}

.footer .box1 {
    /* background-color: red; */
    width: 20vw;
    height: auto;
    display: flex;
    flex-direction: column;
}

.popFooterCat {
    width: 18%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.box1 img {
    position: relative;
    left: -0vw;
    top: -0.5vw;
    width: 11vw;
    height: 5vw;
}

.box1 .desc {
    text-align: left;
    position: relative;
    width: 16vw;
    height: 3.5vw;
}

.box1 .label-input {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    position: relative;
    top: 1.5vw;
}

.box1 .label {
    font-size: 0.7vw;
    width: 13vw;
}

.box1 .input-container {
    display: flex;
    gap: 0.6vw;
    align-items: center;
    margin-bottom: 15%;
}

.box1 .input-container input {
    width: 14vw;
    height: 2.5vw;
    font-size: 1vw;
    outline: none;
    color: white;
    border-radius: 5px;
    border: 0.01vw solid white;
    border-color: #8b8b8b;
    background-color: transparent;
}

.box1 .input-container button {
    text-align: center;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    width: 4vw;
    height: 2.5vw;
    font-size: 1vw;
    border-radius: 0.3vw;
    background: #3B76EF;
    border: none;
    color: #fff;
    outline: none;
    font-family: "Jost", sans-serif;
}

.footer .contact {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10vw;
    width: 55vw;
    height: 23vw;
}

.footer .contact .box2 {
    width: 5vw;
    /* height: 20vw; */
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
}

.footer .bold {
    font-weight: 700;
}

.footer .all {
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}

.footer .all a:hover {
    color: white;
}

.footer .all .icons {
    display: flex;
    gap: 1vw;
}

.footer .all .icons .circle {
    width: 1.5vw;
    height: 1.5vw;
    /*border-radius: 40vw;*/
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.footer .all .icons .circle i {
    font-size: 0.85vw;
}

.footer .contact .box3 {
    /* height: 20vw; */
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    position: relative;
    top: -3vw;
}

.footer .contact .box4 {
    /* height: 20vw; */
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    position: relative;
    top: -1.5vw;
}

.copyright {
    width: 100vw;
    /* width: -moz-available;           WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    min-height: 3.5vw;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.copyright div {
    width: 20vw;
    width: -moz-available;
    width: -moz-max-content;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    height: 1.5vw;
    text-align: center;
    font-size: 1vw;
    -webkit-width: 20vw;
}




.DropDown {
    z-index: 2000;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 5vw;
    /* background-color: red; 
    border: 2px solid red;*/
}

@media(min-width:991px) and (max-width:1025px) {
    .DropDown {
        top: 5vw;
    }
}

.DropDownCard {
    padding: 2vw 3.5vw;
    z-index: 1;
    width: 80%;
    height: 28vw;
    clip-path: inset(0vw -1vw -1vw -1vw);
    box-shadow: 0vw 0.2vw 1vw 0vw #71717140;
    border-radius: 0vw 0vw 2vw 2vw;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);
    animation-name: dropDown;
    /* animation-duration: 0.5s; */
    transition: all 3s ease-in-out;
    position: relative;
    left: 18vw;


}

@keyframes dropDown {
    0% {
        background: transparent;
    }

    100% {
        background: white;
    }
}

.content_items {
    width: 100%;
    color: #465154;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.content_item {
    padding-left: 1rem;
}

.sameBox {
    flex-grow: 1;
}

.largeBox {
    flex-grow: 7;
}

.contentBox {
    position: absolute;
    top: 30%;
}

.contentBox .leftSide h2 {
    font-family: Atma;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #1F423B;
}

.contentBox .leftSide p {
    font-family: Atma;
    font-size: 19px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #395B55;
    width: 350px;
    position: relative;
    padding: 0rem 1rem;
    left: -18%;
}

.btn-singUp {
    height: 38px;
    width: 155px;
    border-radius: 30px;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px 0px 5px 0px #000000A6;
    background: #2D5770;
    margin-left: 25%;
    margin-top: 15%;
}

.contentBox .leftSide .btn-singUp:hover {
    cursor: pointer;
}

.contentBox .leftSide .btn-singUp p {
    position: relative;
    top: -8px;
    color: #fff;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    line-height: 27px;
    width: auto;
    padding: 0;
    left: 0;
}

.content-heading {
    font-family: Atma;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1vw;
    color: #33544E;
}

.list_drop {
    height: 90%;
    border-right: 1px solid #8BB0AA;
    overflow: hidden;
}

.list_drop ul {
    padding: 0;
    list-style: none;
    font-family: Atma;
    font-size: 1.4vw;
    font-weight: 500;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    color: #33544E;
}

.list_drop ul li {
    width: 350px;
    display: block;
}

.iconDropDown {
    margin-right: 1.3rem;
}

.middelContent {
    padding-left: 3rem;
}

.DropDown_ResourcesOpen {
    width: 100vw;
    /* height: 90vh; */
    position: fixed;
    top: 6.5vw;
    /* background-color: rgba(0,0,0,0.5); */
    z-index: 2000;
}

.DropDown_ResourcesOpen ul {
    background-color: white;
    border-radius: 0vw 0vw 2vw 2vw;
    box-shadow: 0vw 0.2vw 1vw 0vw #71717140;
    clip-path: inset(0vw -1vw -1vw -1vw);
    font-family: "Raleway", sans-serif;
    font-size: 1vw;
    list-style: none;
    font-weight: 500;
    color: #465154;
    width: 12vw;
    height: auto;
    padding-top: 1vw;
    position: relative;
    left: 72vw;
}


.DropDown_ResourcesOpen ul li {
    padding-bottom: 1vw;
}

.dropDown_textStyle {
    text-decoration: none;
    color: #465154;
}

.dropDown_textStyle:hover {
    font-weight: 800;
    text-decoration: none;
    color: #2A7086;
}

.Active_page,
.Active_page:hover {
    font-weight: 800;
    text-decoration: none;
    color: #2A7086;
}

.mobileSide-bar {
    z-index: 4000 !important;
    background-color: #2A2B2F !important;
    color: #FFFFFF !important;
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.mobileSide-bar .btn-close {
    background-image: url("./Images/Subtract.svg") !important;
    position: inherit !important;
    z-index: 100 !important;
}

.mobile-dropDown {
    width: 100%;
    height: auto;
    margin-top: 20%;
    /* border: 2px solid red; */
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.mobile-menu {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    column-gap: 3vw;
    padding: 3rem 5rem 0rem 0rem;
}

.mobile-dropDown p {
    color: #AFAFAF;
}

.mobile-icon {
    margin-right: 3vw;
    margin-top: 2vw;
}

.mobile-menu-drop {
    display: flex;
    justify-content: space-around;
    column-gap: 38vw;
}

#mobile-dropList {
    display: none;
}

.data-line {
    height: auto;
}

.data-line ul {
    padding: 1rem;
}

.data-line ul li {
    list-style: none;
    text-decoration: underline;
    padding: 1rem;
}

.industries-mobile {
    height: auto;
}

.industries-mobile ul li {
    text-decoration: none;
    list-style: disc;
}

.textstyle-mobile {
    text-decoration: none !important;
    color: #ffffff !important;
}

@media (min-width:768px) and (max-width:1450px) {


    .DropDown {
        display: flex;
        justify-content: inherit;
    }

    .DropDownCard {
        width: 99%;
        height: auto;
        left: inherit;
        margin: auto;
    }

    .contentBox .leftSide p {
        font-size: 17px;
        width: auto;
        padding: inherit;
        left: inherit;
    }

    .btn-singUp {
        margin-left: inherit;
        margin-top: inherit;
    }

}