
 @media screen and (min-width: 769px) and (max-width:1025px){
     .learn-rectangle {
        display: block;
        justify-content: center;
        padding-top: 1vw;
        align-items: center;
        height: auto;
    }
    .learn-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #D6F5FF 100%);
        height: 14vw;
        width: 100vw;
    }
    .learn-hd h1 {
        padding-top: 4vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .create-learn {
        width: 100vw;
        height: auto;
    }
    .learnimg {
        width: 100vw;
        height: auto;
        background: #D6F5FF;
    }
    .moblearn {
        display: none;
    }
    .desklearn {
        margin: auto;
        width: 70vw;
        height: 57vw;
    }
    .empowered {
        background: linear-gradient(180deg, #D6F5FF 0%, #FFFFFF 100%);
        height: 22vw;
        width: 100vw;
    }
    .bold {
        font-weight: bold;
    }
    .three-card-learning {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-learning {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .block-learning {
        border: 3px solid #2D7288;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #BBEFFF;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 2vw;
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
     
        align-items: center;
        text-align: center;
        width: 23vw;
        height: 21vw;
    }
    .cardheadings-learning {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4vw;
        line-height: 150%;
        text-align: center;
        color: #1F5262;
    }
    .dotline-learn {
    
        height: 0.4vw;
        width: 17vw;
    }
    .paragra-learn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 1.1vw;
        line-height: 150%;
        text-align: center;
        color: #164E60;
        padding: 0.5vw;
        margin: 0.5vw;
    }
    .better {
        background: #FFFFFF;
        height: auto;
        padding-top: 8vw;
        width: 100vw;
    }
    .learning-train {
        width: 100vw;
        height: auto;
    }
    .learningtrainimg {
        width: 100vw;
        height: auto;
        background: #FFFFFF;
    }
    .desktrain {
        margin: auto;
        width: 70vw;
        height: 45vw;
    }
    .mobtrain {
        display: none;
    }
    .last-learning {
        background: linear-gradient(180deg, #FFFDFD 0%, #D6F5FF 100%);
        height: 10vw;
    }
} 
@media screen and (min-width: 426px) and (max-width: 769px){
    .learn-rectangle {
        display: block;
        justify-content: center;
        padding-top: 1vw;
        align-items: center;
        height: auto;
    }
    .learn-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #D6F5FF 100%);
        height: 14vw;
        width: 100vw;
    }
    .learn-hd h1 {
        padding-top: 4vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .create-learn {
        width: 100vw;
        height: auto;
    }
    .learnimg {
        width: 100vw;
        height: auto;
        background: #D6F5FF;
    }
    .moblearn {
        display: none;
    }
    .desklearn {
        margin: auto;
        width: 70vw;
        height: 57vw;
    }
    .empowered {
        background: linear-gradient(180deg, #D6F5FF 0%, #FFFFFF 100%);
        height: 22vw;
        width: 100vw;
    }
    .bold {
        font-weight: bold;
    }
    .three-card-learning {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-learning {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .block-learning {
        border: 3px solid #2D7288;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #BBEFFF;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 2vw;
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
     
        align-items: center;
        text-align: center;
        width: 23vw;
        height: 21vw;
    }
    .cardheadings-learning {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4vw;
        line-height: 150%;
        text-align: center;
        color: #1F5262;
    }
    .dotline-learn {
    
        height: 0.4vw;
        width: 17vw;
    }
    .paragra-learn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 1.1vw;
        line-height: 150%;
        text-align: center;
        color: #164E60;
        padding: 0.5vw;
        margin: 0.5vw;
    }
    .better {
        background: #FFFFFF;
        height: auto;
        padding-top: 8vw;
        width: 100vw;
    }
    .learning-train {
        width: 100vw;
        height: auto;
    }
    .learningtrainimg {
        width: 100vw;
        height: auto;
        background: #FFFFFF;
    }
    .desktrain {
        margin: auto;
        width: 70vw;
        height: 45vw;
    }
    .mobtrain {
        display: none;
    }
    .last-learning {
        background: linear-gradient(180deg, #FFFDFD 0%, #D6F5FF 100%);
        height: 10vw;
    }
    
}
@media screen and (min-width: 377px) and (max-width: 426px){
    .learn-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }
    .learn-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #D6F5FF 100%);
        height: 23vw;
        width: 100vw;
    }
    .learn-hd h1 {
        padding-top: 4vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .create-learn {
        width: 100vw;
        height: auto;
    }
    .learnimg {
        width: 100vw;
        height: auto;
        background: #D6F5FF;
    }
    .moblearn {
        display: block;
        margin: auto;
        width: 70vw;
        height: 70vw;
    }
    .desklearn{
        display: none;
    }

    .empowered {
        background: linear-gradient(180deg, #D6F5FF 0%, #FFFFFF 100%);
        height: 22vw;
        width: 100vw;
    }
    .empowered h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        padding: 3vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .three-card-learning {
        background: #FFFFFF;
        height: auto;
    }
    .twocard-learning {
        display: block;
        justify-content: center;
        align-items: center;
    }
    .block-learning {
        border: 3px solid #2D7288;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #BBEFFF;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        margin-bottom: 10vw;
        padding-top: 2vw;
        margin-top: 13vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        width: 50vw;
        height: 52vw;
    }
    .cardheadings-learning {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #1F5262;
    }
    .dotline-learn {
        padding: 1vw;
        height: 4vw;
        width: 44vw;
    }
    .paragra-learn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vw;
        line-height: 150%;
        text-align: center;
        color: #164E60;
        padding: 1vw;
        margin: 1vw;
    }
    .better h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        padding: 5vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .better {
        background: #FFFFFF;
        height: auto;
        padding-top: 8vw;
        width: 100vw;
    }
    .desktrain{
        display: none;
    }
    .mobtrain{
        width: 70vw;
        height: 70vw;
        display: block;
        margin: auto;
    }
    .learningtrainimg {
        width: 100vw;
        height: auto;
        background: #FFFFFF;
    }
    .learning-train {
        width: 100vw;
        height: auto;
    }
    .last-learning {
        background: linear-gradient(180deg, #FFFDFD 0%, #D6F5FF 100%);
        height: 170px;
    }
}
@media (min-width: 321px) and (max-width: 376px){
    .learn-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }
    .learn-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #D6F5FF 100%);
        height: 23vw;
        width: 100vw;
    }
    .learn-hd h1 {
        padding-top: 4vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .create-learn {
        width: 100vw;
        height: auto;
    }
    .learnimg {
        width: 100vw;
        height: auto;
        background: #D6F5FF;
    }
    .moblearn {
        display: block;
        margin: auto;
        width: 70vw;
        height: 70vw;
    }
    .desklearn{
        display: none;
    }

    .empowered {
        background: linear-gradient(180deg, #D6F5FF 0%, #FFFFFF 100%);
        height: 22vw;
        width: 100vw;
    }
    .empowered h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        padding: 3vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .three-card-learning {
        background: #FFFFFF;
        height: auto;
    }
    .twocard-learning {
        display: block;
        justify-content: center;
        align-items: center;
    }
    .block-learning {
        border: 3px solid #2D7288;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #BBEFFF;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        margin-bottom: 10vw;
        padding-top: 2vw;
        margin-top: 13vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 58vw;
        height: 57vw;
    }
    .cardheadings-learning {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #1F5262;
    }
    .dotline-learn {
        padding: 1vw;
        height: 3vw;
        width: 34vw;
    }
    .paragra-learn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        line-height: 150%;
        text-align: center;
        color: #164E60;
        padding: 1vw;
        margin: 1vw;
    }
    .better h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        padding: 5vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .better {
        background: #FFFFFF;
        height: auto;
        padding-top: 8vw;
        width: 100vw;
    }
    .desktrain{
        display: none;
    }
    .mobtrain{
        width: 70vw;
        height: 70vw;
        display: block;
        margin: auto;
    }
    .learningtrainimg {
        width: 100vw;
        height: auto;
        background: #FFFFFF;
    }
    .learning-train {
        width: 100vw;
        height: auto;
    }
    .last-learning {
        background: linear-gradient(180deg, #FFFDFD 0%, #D6F5FF 100%);
        height: 170px;
    }
}

@media screen and (max-width: 320px){
    .learn-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }
    .learn-hd {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #D6F5FF 100%);
        height: 23vw;
        width: 100vw;
    }
    .learn-hd h1 {
        padding-top: 4vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 7vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .create-learn {
        width: 100vw;
        height: auto;
    }
    .learnimg {
        width: 100vw;
        height: auto;
        background: #D6F5FF;
    }
    .moblearn {
        display: block;
        margin: auto;
        width: 70vw;
        height: 70vw;
    }
    .desklearn{
        display: none;
    }

    .empowered {
        background: linear-gradient(180deg, #D6F5FF 0%, #FFFFFF 100%);
        height: 22vw;
        width: 100vw;
    }
    .empowered h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        padding: 3vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .three-card-learning {
        background: #FFFFFF;
        height: auto;
    }
    .twocard-learning {
        display: block;
        justify-content: center;
        align-items: center;
    }
    .block-learning {
        border: 3px solid #2D7288;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px #BBEFFF;
        display: inline-block;
        box-sizing: border-box;
        margin: auto;
        margin-bottom: 10vw;
        padding-top: 4vw;
        margin-top: 13vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 57vw;
        height: 57vw;
    }
    .cardheadings-learning {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #1F5262;
    }
    .dotline-learn {
        padding: 1vw;
        height: 3vw;
        width: 34vw;
    }
    .paragra-learn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        line-height: 150%;
        text-align: center;
        color: #164E60;
        padding: 1vw;
        margin: 1vw;
    }
    .better h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 5vw;
        padding: 6vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #1F5262;
    }
    .better {
        background: #FFFFFF;
        height: auto;
        padding-top: 8vw;
        width: 100vw;
    }
    .desktrain{
        display: none;
    }
    .mobtrain{
        width: 70vw;
        height: 70vw;
        display: block;
        margin: auto;
    }
    .learningtrainimg {
        width: 100vw;
        height: auto;
        background: #FFFFFF;
    }
    .learning-train {
        width: 100vw;
        height: auto;
    }
    .last-learning {
        background: linear-gradient(180deg, #FFFDFD 0%, #D6F5FF 100%);
        height: 170px;
    }
}