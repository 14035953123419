.block_ {
    display: block;
    line-height: 1.2;
    margin-bottom: 10pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_1 {
    border-bottom-color: #4F81BD;
    border-bottom-style: solid;
    border-bottom-width: 1pt;
    color: #17365D;
    display: block;
    font-family: "Calibri", sans-serif;
    font-size: 2em;
    letter-spacing: 0.25pt;
    line-height: 1.2;
    margin-bottom: 15pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 4pt;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_2 {
    color: #4F81BD;
    display: block;
    font-family: "Calibri", sans-serif;
    font-size: 1.125em;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    page-break-after: avoid;
    page-break-inside: avoid
    }
.block_3 {
    display: block;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 36pt;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_4 {
    display: block;
    line-height: 1.2;
    margin-bottom: 10pt;
    margin-left: 36pt;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_5 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 18pt;
    padding-bottom: 0;
    padding-top: 0
    }
.block_6 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 10pt;
    margin-left: 18pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_7 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 18pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_8 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 10pt;
    margin-left: 18pt;
    padding-top: 0
    }
.calibre {
    display: block;
    font-family: "Cambria", serif;
    font-size: 1em;
    margin-bottom: 0;
    margin-left: 5pt;
    margin-right: 5pt;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0
    }
.calibre1 {
    line-height: 1.2;
    text-decoration: none
    }
.calibre2 {
    font-weight: bold;
    line-height: 1.2
    }
.list_ {
    display: block;
    list-style-type: disc;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0
    }

@page {
    margin-bottom: 5pt;
    margin-top: 5pt
    }

