.Card {
    width: 30rem;
    height: 270px;
    border-radius: 15px;
    background-color: #A4C1E1;
    position: relative;
    box-shadow: 0px 4px 4px 0px #508D83CC;

    .card-inner {
        width: 100%;
        height: auto;

        h4 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.2rem 1.5rem 0rem 1.5rem;
            font-family: Lato;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: #2D4A6B;

            img {
                width: 70px;
                height: 20px;
                background: #fff;
            }
        }

        h3 {
            font-family: Lato;
            font-size: 22px;
            font-weight: 800;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
            padding: 0rem 1.5rem 0rem 1.5rem;
        }
    }

    p {
        padding: 0rem 12.5rem 0rem 1.5rem;
        font-family: Lato;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0em;
        text-align: left;
        color: #353535;
    }

    .image-container {
        width: 250px;
        height: 150px;
        border-radius: 15px;
        position: absolute;
        bottom: 0;
        right: 0;
        overflow: hidden;
    }

    .hoverText-container {
        display: none;
        width: 100%;
        height: 150px;
        position: absolute;
        border-radius: 15px;
        bottom: 0;
        right: 0;
        overflow: hidden;
        text-align: center;
        // background: linear-gradient(180deg, rgba(42, 112, 134, 0) 0%, #123862 100%);

        p {
            font-family: Lato;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
            text-decoration: none;
        }
    }

}

.Card:hover .hoverText-container {
    display: block;
    cursor: pointer;
}