@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=EB+Garamond&family=Lobster&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .learn-rectangle {
      display: block;
      justify-content: center;
      padding-top: 1vw;
      align-items: center;
      height: auto;
  }
.moblearn{
    display: none;
}
.desklearn{
    display: block;
}
.mobtrain{
    display: none;
}
.desktrain{
    display: block;
}
.learn-hd {
    margin-top: 10%;
    background: linear-gradient(180deg, #FFFFFF 0%, #D6F5FF 100%);
    height: 4vw;
    width: 100vw;
}
.learn-hd h1 {

    font-family: 'Atma';
    font-style: normal;
    font-weight: 600;
    font-size: 6vw;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #1F5262;
}
.desklearn{
    margin: auto;
    width: 70vw;
    height: 56vw;
}
.learnimg{
    width: 100vw;
    height: auto;
    background: #D6F5FF;
}
.create-learn{
    width: 100vw;
    height: auto;
}
.empowered{
    background: linear-gradient(180deg, #D6F5FF 0%, #FFFFFF 100%);
    height: 16vw;
    width: 100vw;
}
.empowered h1{
font-family: 'Atma';
font-style: normal;
font-weight: 400;
font-size: 4vw;
padding: 2vw;
line-height: 150%;
align-items: center;
text-align: center;
color: #1F5262;
font-weight: bold;
}

.bold{
    font-weight: bold;
}

.three-card-learning{
    background: #FFFFFF;
    height: auto;
    width: 100vw;

}
.block-learning {
    border: 3px solid #2D7288;
    border-radius: 45px;
    box-shadow: 0px 0px 5px 15px #BBEFFF;
    display: inline-block;
    box-sizing: border-box;
    margin: 3vw;
    padding-top: 2vw;
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    width: 23vw;
    height: 21vw;
}
.twocard-learning{
    display: flex;
    justify-content: center;
    align-items: center;
}
.paragra-learn {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1vw;
    line-height: 150%;
    text-align: center;
    color: #164E60;
    padding: 0.5vw;
    margin: 0.5vw;
}
.cardheadings-learning {
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 150%;
    text-align: center;
    color: #1F5262;
}
.dotline-learn {
 
    height: 0.4vw;
    width: 17vw;
}

 .better{
    background: #FFFFFF;
    height: auto;
    padding-top: 1vw;
    width: 100vw;
 }
 .better h1{
font-family: 'Atma';
font-style: normal;
font-weight: 500;
font-size: 4vw;
line-height: 150%;
align-items: center;
text-align: center;
color: #1F5262;
}

.boldd{
    font-weight: bold;
}
.desktrain{
    margin: auto;
    width: 70vw;
    height: 45vw;

}
.learningtrainimg{
    width: 100vw;
    height: auto;
    background:#FFFFFF;
}
.learning-train{
    width: 100vw;
    height: auto;
}
.last-learning{
    background: linear-gradient(180deg, #FFFDFD 0%, #D6F5FF 100%);
    height: 10vw;
    width: 100vw;
}
