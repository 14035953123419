.about-bottom-box-mobile{
    display: none;
}

.about-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);

    .about-intro {
        width: 100%;
        height: 100%;
        padding: 1rem;

        p {
            font-family: Atma;
            font-size: 55px;
            font-weight: 600;
            line-height: 83px;
            letter-spacing: 0em;
            text-align: center;
            color: #33544E;
        }

    }


    @media (min-width:320px) and (max-width:430px) {

        .about-intro p {
            font-size: 18px;
            line-height: 27px;
        }
    }

}

.about-section-container {
    width: 100%;
    height: 100%;
    position: relative;
    // padding-bottom: 8rem;
    background: rgba(219, 255, 248, 1);

    .about-section {
        width: 100%;
        height: 100%;

        .about-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            .about-col {
                width: 50%;
                height: auto;
            }
        }

        .leftSide {
            padding-left: 5rem;
            padding-top: 10rem;
            padding-bottom: 10rem;

            h2 {
                font-family: Atma;
                font-size: 50px;
                font-weight: 500;
                line-height: 75px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(51, 84, 78, 1);

                span {
                    font-weight: 600;
                }
            }

            p {
                font-family: Lato;
                font-size: 20px;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(69, 110, 102, 1);
                padding-top: 0.6rem;
                padding-bottom: 0.6rem;
            }

            h3 {
                font-family: Atma;
                font-size: 30px;
                font-weight: 600;
                line-height: 45px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(69, 110, 102, 1);

            }

            .login-btn_home {
                width: 200px;
                height: 62px;
                background: rgba(16, 202, 166, 1);
                padding-top: 0.7rem;
            }

        }

        .rightSide {
            width: 80%;
            height: 80%;
            margin: auto;
        }



        @media (min-width:320px) and (max-width:430px) {
            .about-row .about-col {
                width: 100%;

                .rightSide-mobile {
                    padding-bottom: 5rem;
                }
            }

            .leftSide {
                padding-left: inherit;
                padding-top: inherit;
                padding-bottom: 5rem;

                h2 {
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;
                    padding: 0.99rem;
                }
            }

        }


    }

    .ArrowImg {
        text-align: center;
        position: absolute;
        bottom: -25%;
        left: 45%;
        z-index: 3;

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width:320px) and (max-width:420px) {
        .ArrowImg {
            width: 60px;
            bottom: -4%
        }
    }
}

.about-bottom-section {

    .heading-Conatiner {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5%;

        .platfrom-heading {
            width: auto;
            height: auto;
            border-radius: 0px 0px 50px 50px;
            background: #DBFFF8;

            h2 {
                font-family: Atma;
                padding: 0 2rem;
                font-size: 60px;
                font-weight: 400;
                line-height: 90px;
                letter-spacing: 0em;
                text-align: center;
                color: #33544E;

                span {
                    font-weight: 700;
                }
            }

            @media (min-width:320px) and (max-width:430px) {
                h2 {
                    font-size: 24px;
                }
            }
        }

    }

    .wapper {
        width: 80%;
        padding-top: 5rem;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        grid-row-gap: 20rem;
        place-content: center;
        place-items: center;
    }

    .ArrowImg {
        text-align: left;
        border: 2px solid red;
        padding-left: 10%;
    }
}

.about-bottom-img {
    padding-top: 5%;
    padding-left: 15%;
}

.about-bottom-img-right {
    padding-right: 15%;
    position: relative;
    bottom: -100px;

    .ArrowImg {
        text-align: right;
    }
}

.about-bottom-box {

    position: relative;
    transform: scale(0.8);

    .about-box-finance {
        width: 300px !important;
        height: 350px !important;
        position: relative;
        left: -180px !important;
        top: -100px !important;
    }

    .about-box-software {
        width: 300px !important;
        height: 350px !important;
        position: relative;
        right: -10px !important;
        top: -100px !important;

    }

    .about-box-healthCare {
        width: 300px !important;
        height: 350px !important;
        position: relative;
        left: -180px !important;
        top: 70px !important;

    }

    .about-box-retail {
        width: 300px !important;
        height: 350px !important;
        position: relative;
        left: -100px !important;
        top: 10px !important;
    }

    .about-box-manufacturing {
        width: 300px !important;
        height: 350px !important;
        position: relative;
        right: 10px !important;
        top: 60px !important;

    }



}

.box-container-read {
    width: 480px;
    height: auto;
}

@media (min-width:320px) and (max-width:430px) {

    .Home_Content .platfrom-container .heading-Conatiner .platfrom-heading h2 {
        padding: 5%;
        line-height: inherit;
        font-size: inherit;
    }

    .box-container-read {
        width: 280px;
    }

    .about-bottom-section .wapper {
        grid-row-gap: 5rem;
    }

    .about-bottom-section {
        padding-bottom: inherit;
    }

    .about-bottom-img-right {
        bottom: -53px;
    }
    .about-bottom-box{
        display: none;
    }
    .Home_Content .ButWhy-container {
        padding-top: 30%;
    }
    .about-bottom-box-mobile{
        display: block;
    }
}

.about-container-section {
    width: 70%;
    height: auto;
    margin: auto;
    border-radius: 50px;
    border: 2px solid rgba(66, 145, 170, 1);
    text-align: center;
    padding: 8rem 10rem;
    position: relative;
    background-color: #FFFFFF;

    h2 {
        font-family: Atma;
        font-size: 75px;
        font-weight: 600;
        line-height: 75px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(45, 114, 136, 1);
        // text-decoration: underline;
        // text-underline-offset: 18px;
        // text-decoration-thickness: 3px;
        border-bottom: 3px solid rgba(45, 114, 136, 1);

        span {
            font-family: Atma;
            font-size: 75px;
            font-weight: 600;
            line-height: 75px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(150, 212, 200, 1);

        }
    }

    p {
        padding-top: 10%;
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(45, 114, 136, 1);

    }
}

.about-container-section::after {
    content: "";
    display: block;
    margin: auto;
    position: absolute;
    bottom: -15%;
    right: -10%;
    width: 100%;
    height: 100%;
    background-color: #B9EEFF;
    z-index: -1;
    filter: blur(50px);
}

@media (min-width:320px) and (max-width:430px) {

    .about-container-section {
        width: 90%;
        padding: initial;

        h2 {
            font-size: 40px;
            margin: 3px;

            span {
                font-size: 50px;
            }
        }

        p {
            font-size: 17px;
            padding: 6%;
            line-height: 30px;
        }
    }

    .login-btn_home {
        margin: auto;
    }

}

.boxcard {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
}

.boxcard::after {
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    background-color: #B4EFE3;
    z-index: -1;
    filter: blur(50px);
}

.our-mission {
    h2 {
        color: #96D4C8;

        span {
            color: #2D7288;
        }
    }
}

.our-mission::after {
    left: -15%;
    right: 0 !important;
    background-color: #B4EFE3;
}