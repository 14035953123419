.image-slider {
    position: relative;
    width: 100%;
    /* height: 300px; */
    margin: auto;
    overflow: hidden;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBFFF8 100%);
    padding: 2vw;
    padding-bottom: 2.5vw;

}

.image-slider h2 {
    font-family: Atma;
    /* font-size: 75px; */
    font-size: 4.8vw;
    font-weight: 600;
    /* line-height: 113px; */
    line-height: 95px; 
    letter-spacing: 0em;
    text-align: center;
    color: #33544E;
}

.image-slider-container {
    width: 80%;
    height: 270px;
    margin: auto;
    border-radius: 80px;
    position: relative;
}

.image-slider-container img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    object-fit: fill;
    border-radius: 80px;
}

.image-slider-container img.activeImg {
    opacity: 1;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination button {
    margin: 0 5px;
    background: white;
    cursor: pointer;
    z-index: 99;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 1px 0px rgba(35, 167, 143, 1);
}

.pagination button.activeImg {
    background: rgba(51, 84, 78, 1);
}

.pagination button.activeImg:nth-child(2n) {
    background: rgba(21, 6, 82, 1);
}

@media (min-width:320px) and (max-width:430px) {

    .image-slider {
        height: inherit;
    }

    .image-slider h2 {
        font-size: 42px;
        line-height: 70px;
    }


}