.login-btn_home {
    width: 55%;
    height: 12%;
    background: #44B0C8;
    border-radius: 30px;
    border: 2px dashed #2D7288;
    color: white;
    font-family: Atma;
    padding-top: 1rem;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.login-btn_home:hover {
    cursor: pointer;
}

.modalStyle {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20% 0;
    overflow-y: hidden !important;
}

.modalStyle_mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20% 0;
    overflow-y: hidden !important;
}

.modal.fade .modal-dialog {
    width: 600px !important;
    height: 550px !important;
    background-color: transparent !important;
    border-radius: 50px !important;
    position: relative;
    padding-bottom: 1rem !important;
    filter: drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.6));
    background: linear-gradient(177.99deg, #DCFFF9 -6.68%, #DCFFF9 71.85%, #2A7086 143.41%) !important;
}

.modal-open {
    overflow: hidden !important;
}



.modal-header {
    border: none;
}

.modal-content {
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
}

.modal-body {
    color: white;
    position: relative;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

}

.popUp-main {
    position: relative;
    height: 450px;
}

.PopupContent {
    width: 100%;
    padding-left: 2rem !important;
}

.data-cenetr-inner {
    width: 430px
}

.data-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.TopContent .popupText h3 {
    font-family: Atma;
    font-size: 70px;
    letter-spacing: 0em;
    color: #065849;
}

.TopContent .popupText h3 span {
    font-family: Atma;
    font-size: 70px;
    font-weight: 700;
    letter-spacing: 0em;
}

.Second-Section {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lato';
    position: inherit !important;
    top: 20%;
    left: 0;
    padding-left: 7rem;
    padding-right: 6rem;

    .login-btn_home {
        background: transparent !important;
        border: none !important;
    }
}

.Second-Section h3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    font-family: 'Lato';
}

.Second-Section h3 span {
    font-weight: 800;
    text-shadow: 2px 0px #fff;
}

.Second-Section p {
    font-size: 35px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    font-family: 'Lato';
    font-style: italic;
}

.backToHome {
    height: 45px !important;
}

.data_container .data_input {
    width: 95%;
}

.PopupContent .login-btn_popup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8% !important;
}

.login-btn_popup .Getdemo {
    width: 150px;
    height: 40px;
    border-radius: 40px;
    background: #065849;
    box-shadow: 0px 0px 8px 0px #1C5466;
    outline: none;
    border: none;
}

.data_container .data_input label {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #1C5466;
}


.data_container .data_input input {
    width: 100%;
    height: 44px;
    margin: 0.5rem;
    padding: 1rem;
    border: 1px solid #2A7086;
    box-shadow: 0px 0px 8px 0px #33544E;
    border-radius: 50px !important;
    outline: none;
}

input::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #B8DAD4 !important;
    padding-left: 0.5rem;
    opacity: 0.5;
}

.backgroundShap {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -2vw;
    left: -2vw;
    z-index: 11;
}

.backgroundShap .backgroundShap_inner1st {
    width: 200px;
    height: 200px;
    background: rgba(56, 127, 241, 0.8);
    filter: blur(77px);
    float: left;
}

.backgroundShap_2nd {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: -2vw;
    right: -2vw;
    z-index: 11;
}

.backgroundShap_2nd .backgroundShap_inner {
    width: 200px;
    height: 200px;
    background: rgba(207, 97, 203, 0.4);
    filter: blur(77px);
}



/* .closebtn_ {
    width: 90%;
} */

.login-btn_popup .loginBtn {
    width: 100% !important;
    height: 40px !important;
    color: white !important;
    text-align: center;
    border-radius: 15px 0px 15px 0px;
}

.login-btn_popup .loginBtn:hover {
    color: white !important;
    background: transparent !important;
}

.border-gradient-popUp {
    border-width: 2px;
    border-radius: 15px 0px 15px 0px;
    border: double 2px transparent;
    background-image: linear-gradient(#111111, #111111),
        linear-gradient(180deg, #387FF1 0%, #CF61CB 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;

}

.btn-close {
    position: absolute;
    right: 2vw;
    top: 1vw;
    z-index: 11 !important;
    background: transparent url("../../../Images/closebtn.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}



@media (min-width:320px) and (max-width:430px) {

    // .data-center {
    //     margin-top: 10%;
    // }

    // .modal-body {
    //     height: 333px;
    // }

    /* .popUp-main {
        height: 400px !important;
    } */

    .btn-close {
        right: 8vw !important;
        top: -5vw !important;
    }

    .popupText {
        font-size: 12px !important;
        line-height: 20px !important;
        padding: inherit !important;

        h3 {
            text-align: center;

            span{
                font-size: 40px !important;
            }
        }
    }

    .backgroundShap {
        width: 100px !important;
        height: 100px !important;
        top: 12vw !important;
        left: -8vw !important;
    }

    .backgroundShap .backgroundShap_inner1st {
        width: 100px !important;
        height: 100px !important;
        filter: blur(30px);
    }

    .popupText h3 {
        font-size: 33px !important;
    }

    .PopupContent {
        padding-left: inherit !important;

    }

    .modal.fade .modal-dialog {
        width: 90% !important;
        height: 400px !important;
    }

    .Second-Section {
        position: absolute;
        top: 10%;
        padding-left: inherit !important;
        padding-right: inherit !important;
    }

    .Second-Section h3 {
        font-size: 25px !important;
        line-height: inherit !important;
    }

    .Second-Section p {
        font-size: 25px !important;
        line-height: inherit !important;
    }
}

@media screen and (max-width:320px) {
    .btn-close {
        right: 8vw !important;
        top: 0vw !important;
    }
}