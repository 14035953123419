.Home_Content {
    width: 100vw;
    position: relative;
    overflow: hidden;

    .singUp-Container {
        width: 100%;
        height: 100%;
        background-color: #DBFFF8;
        position: relative;

        .leftSide {
            width: 100%;
            height: 80%;
            // padding-top: 15%;
            padding-top: 1vw;

            h2 {
                font-family: Atma;
                font-size: 40px;
                font-weight: 400;
                letter-spacing: 0em;
                text-align: left;
                color: #33544E;
                font-style: normal;
                text-transform: uppercase;
                line-height: 150%;

                span {
                    font-weight: 600;
                }
            }

            p {
                font-family: Lato;
                font-size: 22px;
                font-weight: 500;
                line-height: 170%;
                letter-spacing: 0em;
                text-align: start;
                color: #456E66;
                padding: 0rem 15rem 0rem 0rem;
            }

            h3 {
                font-family: Atma;
                font-size: 4.1vw;
                font-weight: 400;
                line-height: 70px;
                letter-spacing: 0em;
                text-align: left;
                color: #33544E;
                padding-bottom: 0.8vw;

                span {
                    font-weight: 700;
                }
            }

            @media (min-width:768px) and (max-width:1025px) {

                h2 {
                    font-size: 38px;
                }

                h3 {
                    font-size: 50px;

                    span {
                        font-weight: 700;
                    }
                }

                p {
                    font-size: 18px;
                    padding: 0rem;
                }

                .login-btn_home {
                    position: relative;
                    z-index: 99;
                    width: 100%;
                    height: 20%;
                }

            }

            @media (min-width:320px) and (max-width:430px) {

                h2 {
                    font-size: 20px;
                    text-align: center;
                }

                h3 {
                    font-size: 50px;

                    span {
                        font-weight: 700;
                    }
                }

                p {
                    font-size: 18px;
                    padding: 0rem;
                }

                .login-btn_home {
                    font-size: 20px;
                }

            }
        }

        .rightSide {
            width: 100%;
            height: 80%;
            padding-top: 0.8vw;
        }

        .rightSide-mobile {


            @media (min-width:320px) and (max-width:430px) {

                .login-btn_home {
                    width: auto;
                    font-size: 20px;
                    padding-top: inherit;
                    padding: 0.5rem;
                    background-color: #10CAA6;
                }

            }
        }

        .ArrowImg {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: -20%;
            background: linear-gradient(180deg, #DBFFF8 0%, #FFFCFC 100%);

            // img {
            //     margin-right: 4%;
            // }
        }

        @media (min-width:990px) and (max-width:1025px) {
            .ArrowImg {
                bottom: -20%;
            }
        }

        @media (min-width:768px) and (max-width:990px) {
            .ArrowImg {
                bottom: inherit;
            }
        }

        @media (min-width:320px) and (max-width: 430px) {
            .ArrowImg {
                bottom: inherit;
            }
        }

    }

    .EductionSop-Container {
        width: 100%;
        height: 100%;
        padding-top: 15%;
        padding-bottom: 15%;
        position: relative;

        .leftSide {
            width: 100%;
            height: 100%;
            padding-top: 15%;

            h2 {
                font-family: Atma;
                font-size: 40px;
                font-weight: 400;
                letter-spacing: 0em;
                text-align: right;
                color: #1C5466;
                font-style: normal;
                text-transform: uppercase;
                line-height: 150%;

                span {
                    font-weight: 600;
                }
            }

            p {
                font-family: Lato;
                font-size: 22px;
                font-weight: 500;
                line-height: 170%;
                letter-spacing: 0em;
                text-align: right;
                color: #2D7288;
                padding: 2.5rem 0rem 0rem 6rem;
            }

            img {
                display: none;
            }

            @media (min-width:768px) and (max-width:1025px) {

                h2 {
                    font-size: 30px;
                }

                p {
                    font-size: 18px;
                    padding: 0rem;
                }
            }

            @media (min-width:320px) and (max-width: 430px) {
                h2 {
                    font-size: 20px;
                    text-align: center;
                    padding-bottom: 3rem;
                }

                img {
                    display: block;
                }

                p {
                    display: block;
                    font-size: 16px;
                    text-align: center;
                    padding: 2.5rem 0rem 2.5rem 0rem;
                }
            }

        }

        .login-btn_home {
            float: right;
            position: relative;
            bottom: 20%;
        }

        .rightSide {
            width: 100%;
            height: 100%;
        }

        .ArrowImg {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: -20%;
        }


        @media (min-width:768px) and (max-width:1025px) {

            .login-btn_home {
                position: relative;
                z-index: 99;
                width: 100%;
                height: 16%;
                bottom: 10%;
            }

            .ArrowImg {
                bottom: -15%;
            }

        }

        @media (min-width:320px) and (max-width: 430px) {

            .login-btn_home {
                width: auto;
                font-size: 20px;
                padding: 0.9rem;
                height: inherit;
                margin-top: 5%;
            }

            .ArrowImg {
                bottom: inherit;

                img {
                    width: 20%;
                    height: 20%;
                }
            }

        }

    }

    .ButWhy-container {
        width: 100%;
        height: 100%;
        padding-top: 10%;
        padding-bottom: 15%;
        text-align: center;
        position: relative;

        .heading-Conatiner {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5%;

            .platfrom-heading {
                width: auto;
                height: auto;
                border-radius: 0px 0px 50px 50px;
                background: #DBFFF8;

                h2 {
                    font-family: Atma;
                    padding: 0 2rem;
                    font-size: 60px;
                    font-weight: 600;
                    line-height: 90px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #33544E;
                }

                @media (min-width:768px) and (max-width:1025px) {
                    h2 {
                        font-size: 50px;
                    }
                }

                @media (min-width:320px) and (max-width:430px) {
                    h2 {
                        font-size: 24px;
                        padding: 0rem 4rem;
                    }
                }
            }

        }

        .platfrom-containerInner {
            width: 100%;
            height: 100%;
            padding: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .platfrom-img-container {
            width: 70%;
            height: 70%;
            position: relative;

            .Platfroms {
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: -8%;

                .Platfroms-topItems {
                    width: 100%;
                    height: 33%;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: -25%;

                    .bookHouse {
                        position: relative;
                        transform: translate(-250%, 30%);
                    }

                    .mobile {
                        position: relative;
                        // transform: translate(-120%,-200%);
                        top: -110%;
                        left: -10%;
                    }

                    .Quizzes {
                        position: relative;
                        left: 20%;
                        top: 18%;
                    }

                    @media (min-width:768px) and (max-width:1025px) {

                        .bookHouse {
                            transform: translate(-210%, -33%);
                        }

                        .mobile {
                            top: -170%;
                            left: -16%;
                        }

                        .Quizzes {
                            left: 15%;
                            top: -30%;
                        }

                        .BuildCourses {
                            margin-left: -52%;
                            margin-top: -20%;
                        }

                        .Integrations {
                            margin-top: 0%;
                            margin-right: inherit;
                            margin-left: -10%;
                        }
                    }


                }

                .Platfroms-MiddelItems {
                    width: 100%;
                    height: 33%;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    bottom: -15%;

                    .BuildCourses {
                        margin-left: -40%;
                        margin-top: -10%;
                    }

                    .Gamification {
                        margin-right: -20%;
                        margin-top: -10%;

                    }

                    @media (min-width:768px) and (max-width:1025px) {

                        .BuildCourses {
                            margin-left: -52%;
                            margin-top: -20%;
                        }

                        .Gamification {
                            margin-top: -20%;
                        }
                    }


                }

                .Platfroms-bottomItems {
                    width: 100%;
                    height: 33%;
                    position: absolute;
                    bottom: -20%;
                    display: flex;
                    justify-content: space-between;

                    .Integrations {
                        margin-top: 10%;
                        margin-right: 25%;
                    }

                    .Insights {
                        margin-top: 10%;
                        margin-right: 30%;
                    }

                    @media (min-width:768px) and (max-width:1025px) {

                        .Integrations {
                            margin-top: 0%;
                            margin-right: inherit;
                            margin-left: -10%;
                        }

                        .Insights {
                            margin-top: -3%;
                            margin-right: 30%;
                        }
                    }

                }

                .platfromItem {
                    width: 100px;
                    height: 100px;
                }
            }
        }

        .ArrowImg {
            width: 100%;
            text-align: left;
            position: absolute;
            bottom: -13%;

            img {
                margin-left: 10%;
            }
        }

        .platfrom-containerInner-mobile {
            display: none;
        }

        @media (min-width:760px) and (max-width:780px) {

            .ArrowImg {
                bottom: -10%;
            }

        }

        @media (min-width:768px) and (max-width:1025px) {

            .ArrowImg {
                bottom: -15%;

                img {
                    margin-left: 5%;
                }
            }
        }

        @media (min-width:374px) and (max-width:430px) {
            .platfrom-containerInner-mobile {

                img {
                    width: 87%;
                }
            }

        }

        @media (min-width:341px) and (max-width:374px) {
            .platfrom-containerInner-mobile {

                img {
                    width: 87%;
                }
            }

        }



        @media (min-width:320px) and (max-width:430px) {

            .platfrom-containerInner {
                display: none;
            }

            .platfrom-containerInner-mobile {
                display: block;
                border: 2px solid transparent;
                width: 100%;
                height: 100%;
                padding: 40%;
                padding-top: 20%;
                padding-bottom: 0;

                .platfrom-img-container {
                    width: 100%;
                    height: 100%;

                    .Platfroms {
                        position: absolute;
                        top: 7%;
                        left: -5.5rem;
                        bottom: inherit;

                        .platfromItem {
                            width: 100%;
                            height: auto;
                            margin-bottom: 6rem;

                            .Card-Container {
                                background: #FFFDFD;
                                box-shadow: 0px 0px 4px 0px #00000040;
                                padding: 1rem;
                                border-radius: 15px;
                            }

                        }

                        .Insights {
                            margin-bottom: 0rem;
                        }

                    }

                }


            }

        }



    }

    .contentGrowing-Container {
        width: 100%;
        height: 100%;
        padding-top: 15%;
        position: relative;
        text-align: center;

        h2 {
            font-family: Atma;
            font-size: 60px;
            font-weight: 600;
            letter-spacing: 0em;
            text-align: center;
            color: #1C5466;
            font-style: normal;
            text-transform: uppercase;
            line-height: 150%;
        }

        .Content-Card-Container {
            width: 100%;
            height: auto;
            padding-top: 3%;
            display: flex;
            justify-content: center;
            align-items: center;

            .Content-Card {
                width: 900px;
                height: 650px;
                border-radius: 40px;
                border: 4px solid #2A7086;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #F4FCFF;

                .content-cardInner {
                    height: 100%;
                    position: relative;
                    padding: 0.5rem;

                    .card-item {
                        width: 700px;
                        height: 80px;
                        border-radius: 10px;
                        padding-left: 3rem;
                        background-color: #4291AA;
                        border: 3px solid #1C5466;
                        margin: 1rem;
                        display: flex;
                        justify-content: start;

                        p {
                            height: 80px;
                            font-family: Lato;
                            font-size: 25px;
                            font-weight: 600;
                            padding-top: 1rem;
                            padding-left: 3rem;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #ffff;
                            text-transform: uppercase;
                        }

                    }

                    .link-more {
                        position: absolute;
                        top: 15px;
                        margin-top: 0 !important;
                        margin-bottom: 18%;
                        box-shadow: none !important;

                        a {
                            color: #2A7086 !important;
                        }
                    }

                    .Link-More {
                        width: 100%;
                        height: 55px;
                        border: 2px solid #1C5466;
                        margin-top: 12%;
                        font-family: Raleway;
                        font-size: 40px;
                        font-weight: 500;
                        position: relative;
                        border-radius: 10px;
                        box-shadow: 0px 0px 3px 0px #1C5466;

                        a {
                            color: #2A7086;
                            text-decoration: none;
                            line-height: 150%;
                            position: relative;
                            top: -5px;

                            img {
                                float: right;
                                margin-right: 3%;
                                margin-top: 1.8%;
                            }
                        }

                        .Circle-div {
                            float: right;
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            background-color: #1C5466;
                            margin-top: 0.8rem;
                            margin-right: 0.8rem;
                        }
                    }


                    @media (min-width:320px) and (max-width:430px) {
                        .card-item {
                            width: 260px;
                            height: 60px;
                            padding-top: 0.4rem;

                            img {
                                width: 40px;
                                height: 40px;
                            }

                            p {
                                font-size: 14px;
                                padding-top: 0.5rem;
                                padding-left: 1.5rem;
                            }
                        }

                        .Link-More {
                            margin-bottom: 5%;
                            width: 95%;
                            margin: auto;

                            a {
                                font-size: 30px;

                                img {
                                    margin-top: 5%;
                                }
                            }
                        }

                        .link-more {
                            top: 10px;
                        }

                    }

                }


            }

            @media (min-width:320px) and (max-width:430px) {
                .Content-Card {
                    height: auto;
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }

            }

        }

        .ArrowImg {
            width: 100%;
            text-align: right;
            position: absolute;
            bottom: -35%;

            img {
                margin-right: 15%;
            }
        }

        @media (min-width:780px) and (max-width:1025px) {

            .Content-Card-Container {
                width: 90%;
                margin: auto;
            }

            .ArrowImg {
                margin: 8%;
            }

            h2 {
                font-size: 45px;
            }
        }


        @media (min-width:760px) and (max-width:780px) {
            .ArrowImg {
                margin-top: 5%;
                margin-left: 13%;
                bottom: inherit;
            }
        }


        @media (min-width:320px) and (max-width:430px) {

            h2 {
                font-size: 24px;
            }

            .contentGrowing-Container {
                width: 90%;
                margin: auto;
            }

            .ArrowImg {
                bottom: -35%;
                transform: rotate(-20deg);

                img {
                    width: 30%;
                    margin-right: 0;
                }
            }
        }

    }

    @media (min-width:320px) and (max-width:430px) {

        .contentGrowing-Container {
            width: 90%;
            margin: auto;
        }

    }


    .platfrom-container {
        width: 100%;
        height: 100%;
        padding-top: 15%;
        padding-bottom: 10%;
        text-align: center;

        .heading-Conatiner {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5%;

            .platfrom-heading {
                width: auto;
                height: auto;
                border-radius: 0px 0px 50px 50px;
                background: #DBFFF8;

                h2 {
                    font-family: Atma;
                    padding: 0 2rem;
                    font-size: 60px;
                    font-weight: 600;
                    line-height: 90px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #33544E;

                    span {
                        font-weight: 600;
                    }
                }

                @media (min-width:320px) and (max-width:430px) {
                    h2 {
                        font-size: 24px;
                    }
                }
            }

        }

        .platfrom-containerInner {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .platfrom-img-container {
            width: 50%;
            height: 50%;
            position: relative;


            .Platfroms {
                width: 100%;
                height: 100%;
                // border: 2px solid black;
                position: absolute;
                bottom: -8%;

                .Platfroms-topItems {
                    width: 100%;
                    height: 70%;
                    display: flex;
                    justify-content: space-between;

                }

                .Platfroms-bottomItems {
                    width: 100%;
                    height: 30%;
                    display: flex;
                    justify-content: space-between;
                }

                .platfromItem {
                    width: 100px;
                    height: 100px;
                }

                .Courses {
                    margin-left: -20%;
                }

                .Feedback {
                    margin-right: -20%;

                }

                .Quizzes {
                    margin-top: 15%;
                }

                @media (min-width:768px) and (max-width:1025px) {
                    .Platfroms-topItems {
                        width: 108%;
                    }
                }
            }
        }

        @media (min-width:320px) and (max-width:430px) {

            .platfrom-containerInner {
                padding-top: 10%;
                width: 95%;
                margin: auto;
            }

            .platfrom-img-container {
                width: 60%;
                height: 60%;

                .Platfroms {
                    bottom: 44%;
                    padding-top: 5%;

                    .Platfroms-topItems {
                        height: 100%;

                        .Courses {
                            margin-left: -44%;
                            transform: scale(0.5);
                        }

                        .Feedback {
                            margin-right: -44%;
                            transform: scale(0.5);
                        }
                    }

                    .Platfroms-bottomItems {
                        padding-top: 10%;

                        .LearningPath {
                            position: absolute;
                            transform: scale(0.5);
                            left: -33%;
                            top: 90%;
                        }

                        .Quizzes {
                            margin-right: 0;
                            transform: scale(0.5);
                            position: absolute;
                            top: 100%;
                            left: 22%;
                        }

                        .ProductHub {
                            transform: scale(0.5);
                            position: absolute;
                            right: -34%;
                            top: 100%;
                        }
                    }
                }
            }

        }
    }


    .GetDemo-Container {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        padding-top: 15%;
        padding-bottom: 15%;

        .leftSide {
            width: 100%;
            height: 100%;
            padding-top: 15%;

            h2 {
                font-family: Atma;
                font-size: 70px;
                font-weight: 400;
                letter-spacing: 0em;
                text-align: left;
                color: #33544E;
                font-style: normal;
                text-transform: uppercase;
                line-height: 150%;

                span {
                    font-weight: 600;
                }
            }

            p {
                font-family: Lato;
                font-size: 28px;
                font-weight: 400;
                line-height: 170%;
                letter-spacing: 0em;
                text-align: left;
                color: #456E66;
                padding: 1rem 15rem 0rem 0rem;
            }

            .leftSide-getDemo {
                text-align: left;
                padding-top: 0.5rem;
                position: relative;

                input {
                    width: 50%;
                    height: 53px;
                    box-shadow: 0px 0px 8px 0px #33544E;
                    border-radius: 50px;
                    border: none;
                    outline: none;
                    padding-left: 1rem;
                }

                input::placeholder {
                    color: #96D4C8;
                }

                button {
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    margin-left: 3%;
                    background-color: #DBFFF8;
                    border: 2px dashed #2D7288;
                    border-radius: 42%;
                    position: absolute;
                    top: 15px;

                    img {
                        text-align: center;
                        margin-left: 10%;
                        margin-top: 10%;
                    }
                }

                button:hover {
                    cursor: pointer;
                }

                @media (min-width:768px) and (max-width:1025px) {
                    h2 {
                        font-size: 50px;
                    }

                    p {
                        padding: 0rem;
                    }

                    input {
                        width: 90%;
                    }

                }

            }

            @media (min-width:768px) and (max-width:1025px) {
                h2 {
                    font-size: 50px;
                }

                p {
                    padding: 0rem;
                }

            }

            @media (min-width:320px) and (max-width:430px) {
                h2 {
                    font-size: 30px;
                    text-align: center;
                }

                .leftSide-getDemo {
                    display: none;
                }
            }
        }

        .rightSide {
            width: 100%;
            height: 100%;
        }

        .rightSide-mobile {
            width: 100%;
            height: 100%;
            padding-bottom: 5%;
            padding-top: 10%;

            @media (min-width:320px) and (max-width:430px) {
                p {
                    font-family: Lato;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #456E66;
                }

                .leftSide-getDemo {
                    text-align: left;
                    padding-top: 0.5rem;
                    position: relative;

                    input {
                        width: 80%;
                        height: 50px;
                        box-shadow: 0px 0px 8px 0px #33544E;
                        border-radius: 50px;
                        border: none;
                        outline: none;
                        padding-left: 1rem;
                    }

                    input::placeholder {
                        color: #96D4C8;
                    }

                    button {
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        margin-left: 8%;
                        background-color: #DBFFF8;
                        border: 2px dashed #2D7288;
                        border-radius: 42%;
                        position: absolute;
                        top: 12px;

                        img {
                            text-align: center;
                            margin-left: 10%;
                            margin-top: 10%;
                        }
                    }

                    button:hover {
                        cursor: pointer;
                    }
                }

            }

        }

        .ArrowImg {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: -20%;
        }


    }

    @media (min-width:320px) and (max-width:420px) {


        .platfrom-container {
            padding-top: 50%;
        }

        .GetDemo-Container {
            padding-top: 40%;
            padding-bottom: inherit;
        }

        .footer-container {
            position: relative;
            top: 45vw;
            height: auto;
        }

    }

}

.top-container {
    width: 100%;
    height: 20vh;
    border: 2px solid black;

}

//brekpoint working 
@media (min-width:1024px) and (max-width:1030px){
    .Home_Content .ButWhy-container .platfrom-img-container {
        width: 60%;
    }
}

@media (min-width:1030px) and (max-width:1400px) {

    .Home_Content .singUp-Container .ArrowImg {
        bottom: -19%;
    }

    .login-btn_home {
        height: auto;
        width: 370px;
        padding: 1rem;
        position: relative;
        z-index: 9;
    }

    .Home_Content .EductionSop-Container .login-btn_home {
        bottom: 15%;
    }

    .Home_Content .singUp-Container .leftSide p {
        padding: 0rem;
    }

    .Home_Content .EductionSop-Container .ArrowImg {
        bottom: -12%;
    }

    .Home_Content .ButWhy-container .platfrom-containerInner {
        padding: 10%;
    }

    .Home_Content .ButWhy-container .platfrom-img-container {
        transform: scale(0.7);
    }

    .Home_Content .ButWhy-container .ArrowImg {
        bottom: -7%;
    }

    .Home_Content .contentGrowing-Container .ArrowImg {
        bottom: -23%;
        right: -5%;
    }

    .Home_Content .GetDemo-Container .leftSide p {
        padding: 0rem;
    }


}