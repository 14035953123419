@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=EB+Garamond&family=Lobster&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .product-headding{
    margin-top: 10%;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFDE3 100%);
    height: 316px;
}

.product-headding h1{
padding-top: 20px;
font-family: 'Atma';
font-style: normal;
font-weight: 600;
font-size: 95px;
line-height: 150%;
align-items: center;
text-align: center;
color: #4E490F;
height: 80px;
}

.createproductimg{
    text-align: center;
    height: 664px;
    padding-top: 30px;
    
}
.create-product{
    background: #FFFDE3;
    height: 750px;
}

.smarter-product{
    background: linear-gradient(180deg, #FFFDE3 0%, #FFFFFF 100%);
    height: 460px;
}

.smarter-product h1{
font-family: 'Atma';
font-style: normal;
font-weight: 400;
font-size: 65px;
line-height: 150%;
align-items: center;
text-align: center;
color: #4E490F;
height: 50px;
}
.bold{
    font-weight: bold;
}
.three-carrds-product{
    background: #FFFFFF;
    height: 1500px;

}
.blok-product{
    border: 2px solid #2D7288;
    border-radius: 45px;
    box-shadow: 0px 0px 5px 20px#FFFBCD;;
    display: inline-block;
    box-sizing: border-box;
    margin: 50px;
    padding-top: 50px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 545.35px;
    height: 491.29px;
}
.twocard-product{
    display: flex;
    justify-content: center;
    align-items: center;
}
.paragra-product{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 150%;
    text-align: center;  
    color:  #706A1A;
    padding: 20px;
    margin: 20px;
}
.cardheadings-product{
    font-family: 'Atma';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 150%;
    text-align: center;
    color: #4E490F;
}
.dotlineimgg{
    padding: 10px;
 }


 .time-savingg-product{
    background: #FFFFFF;
    height: 200px;
}

.time-savingg-product h1{

font-family: 'Atma';
font-style: normal;
font-weight: 400;
font-size: 65px;
line-height: 150%;
align-items: center;
text-align: center;
color: #4E490F;
height: 60px;
padding-top: 20px;

}
.boldd{
    font-weight: bold;
}

.consolidated-platformm{
    background: #FFFFFF;
    height: 1000px;
}
.consolidatedd{
    text-align: center;
    height: 800px;
   
}
.lastdiv-product{
    background: linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%);
    height: 200px;
}

.mobprodcons{
    display: none;
}
.mobcreateprod{
    display: none;
}