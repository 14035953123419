
@media screen and (min-width: 769px) and (max-width:1025px){
    .product-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .product-headding h1 {
        padding-top: 13vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 80px; */
    }

    .product-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFDE3 100%);
        height: 12vw;
        width: 100vw;
    }
    .mobprodcons{
        display: none;
    }
    .mobcreateprod{
        display: none;
    }
    .deskprodcons{
        display: block;
    }
    .deskcreateprod{
        display: block;
    }
    .deskcreateprod {
        display: block;
        margin: auto;
        width: 92vw;
        height: 153vw;
    }

    .createproductimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .create-product {
        background: #FFFDE3;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .smarter-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 50px; */
    }


    .smarter-product {
        background: linear-gradient(180deg, #FFFDE3 0%, #FFFFFF 100%);
        height: 38vw;
    }

    .three-carrds-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-product {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .blok-product {
        border: 2px solid #4E490F;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#FFFBCD;
        display: inline-block;
        box-sizing: border-box;
        margin: 3vw;
        padding-top: 10vw;
        margin-top: 8vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 60vw;
        height: 60vw;
    }
    .cardheadings-product {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #4E490F;
    }
    .paragra-product {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        line-height: 150%;
        text-align: center;
        color:  #706A1A;
        padding: 2vw;
        margin: 2vw;
    }

    .dotlineimgg {
        padding: 10px;
        width: 44vw;
        height: 8vw;
    }
    .time-savingg-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 60px; */
        /* padding-top: 20px; */
    }
    .time-savingg-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .deskprodcons {
        display: block;
        margin: auto;
        width: 81vw;
        height: 96vw;
    }
    .consolidatedd {
        text-align: center;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .consolidated-platformm {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .lastdiv-product {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%);
        height: 22vw;
        width: 100vw;
    }

}

@media screen and (min-width: 426px) and (max-width: 769px){
    .product-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .product-headding h1 {
        padding-top: 13vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 80px; */
    }

    .product-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFDE3 100%);
        height: 12vw;
        width: 100vw;
    }
    .mobprodcons{
        display: none;
    }
    .mobcreateprod{
        display: none;
    }
    .deskprodcons{
        display: block;
    }
    .deskcreateprod{
        display: block;
    }
    .deskcreateprod {
        display: block;
        margin: auto;
        width: 92vw;
        height: 153vw;
    }

    .createproductimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .create-product {
        background: #FFFDE3;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .smarter-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 50px; */
    }


    .smarter-product {
        background: linear-gradient(180deg, #FFFDE3 0%, #FFFFFF 100%);
        height: 38vw;
    }

    .three-carrds-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-product {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .blok-product {
        border: 2px solid #4E490F;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#FFFBCD;
        display: inline-block;
        box-sizing: border-box;
        margin: 2vw;
        padding-top: 10vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 47vw;
        height: 55vw;
    }
    .cardheadings-product {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color: #4E490F;
    }
    .paragra-product {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        line-height: 150%;
        text-align: center;
        color:  #706A1A;
        padding: 2vw;
        margin: 2vw;
    }

    .dotlineimgg {
        padding: 10px;
        width: 44vw;
        height: 8vw;
    }
    .time-savingg-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 60px; */
        /* padding-top: 20px; */
    }
    .time-savingg-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .deskprodcons {
        display: block;
        margin: auto;
        width: 81vw;
        height: 96vw;
    }
    .consolidatedd {
        text-align: center;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .consolidated-platformm {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .lastdiv-product {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%);
        height: 22vw;
        width: 100vw;
    }

}

@media screen and (min-width: 377px) and (max-width: 426px){
    .product-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .product-headding h1 {
        padding-top: 13vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 80px; */
    }

    .product-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFDE3 100%);
        height: 12vw;
        width: 100vw;
    }
    .deskprodcons{
        display: none;
    }
    .deskcreateprod{
        display: none;
    }
    .mobprodcons{
        display: block;
    }
    .mobcreateprod{
        display: block;
    }
    .mobcreateprod {
        display: block;
        margin: auto;
        width: 92vw;
        height: 153vw;
    }

    .createproductimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .create-product {
        background: #FFFDE3;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .smarter-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 50px; */
    }


    .smarter-product {
        background: linear-gradient(180deg, #FFFDE3 0%, #FFFFFF 100%);
        height: 38vw;
    }

    .three-carrds-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-product {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .blok-product {
        border: 2px solid #4E490F;;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#FFFBCD;;
        display: inline-block;
        box-sizing: border-box;
        margin: 11vw;
        padding-top: 10vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 72vw;
        height: 86vw;
    }
    .cardheadings-product {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #4E490F;
    }
    .paragra-product {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color:  #706A1A;
        padding: 5vw;
        margin: 5vw;
    }

    .dotlineimgg {
        padding: 10px;
        width: 44vw;
        height: 8vw;
    }
    .time-savingg-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 60px; */
        /* padding-top: 20px; */
    }
    .time-savingg-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .mobprodcons {
        display: block;
        margin: auto;
        width: 81vw;
        height: 96vw;
    }
    .consolidatedd {
        text-align: center;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .consolidated-platformm {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .lastdiv-product {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%);
        height: 22vw;
        width: 100vw;
    }

}

@media (min-width: 321px) and (max-width: 376px){
    .product-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .product-headding h1 {
        padding-top: 13vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 80px; */
    }

    .product-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFDE3 100%);
        height: 12vw;
        width: 100vw;
    }
    .deskprodcons{
        display: none;
    }
    .deskcreateprod{
        display: none;
    }
    .mobprodcons{
        display: block;
    }
    .mobcreateprod{
        display: block;
    }
    .mobcreateprod {
        display: block;
        margin: auto;
        width: 92vw;
        height: 153vw;
    }

    .createproductimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .create-product {
        background: #FFFDE3;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .smarter-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 50px; */
    }


    .smarter-product {
        background: linear-gradient(180deg, #FFFDE3 0%, #FFFFFF 100%);
        height: 38vw;
    }

    .three-carrds-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-product {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .blok-product {
        border: 2px solid #4E490F;;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#FFFBCD;;
        display: inline-block;
        box-sizing: border-box;
        margin: 11vw;
        padding-top: 10vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 72vw;
        height: 86vw;
    }
    .cardheadings-product {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #4E490F;
    }
    .paragra-product {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color:  #706A1A;
        padding: 5vw;
        margin: 5vw;
    }

    .dotlineimgg {
        padding: 10px;
        width: 44vw;
        height: 8vw;
    }
    .time-savingg-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 60px; */
        /* padding-top: 20px; */
    }
    .time-savingg-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .mobprodcons {
        display: block;
        margin: auto;
        width: 81vw;
        height: 96vw;
    }
    .consolidatedd {
        text-align: center;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .consolidated-platformm {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .lastdiv-product {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%);
        height: 22vw;
        width: 100vw;
    }

}

@media screen and (max-width: 320px){
    .product-rectangle {
        display: block;
        justify-content: center;
        padding-top: 10vw;
        align-items: center;
        height: auto;
    }

    .product-headding h1 {
        padding-top: 13vw;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 80px; */
    }

    .product-headding {
        margin-top: 10%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFDE3 100%);
        height: 12vw;
        width: 100vw;
    }
    .deskprodcons{
        display: none;
    }
    .deskcreateprod{
        display: none;
    }
    .mobprodcons{
        display: block;
    }
    .mobcreateprod{
        display: block;
    }
    .mobcreateprod {
        display: block;
        margin: auto;
        width: 92vw;
        height: 153vw;
    }

    .createproductimg {
        text-align: center;
        height: auto;
        /* padding-top: 30px; */
        width: 100vw;
    }

    .create-product {
        background: #FFFDE3;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .smarter-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 50px; */
    }


    .smarter-product {
        background: linear-gradient(180deg, #FFFDE3 0%, #FFFFFF 100%);
        height: 38vw;
    }

    .three-carrds-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .twocard-product {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .blok-product {
        border: 2px solid #4E490F;;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 10px#FFFBCD;;
        display: inline-block;
        box-sizing: border-box;
        margin: 11vw;
        padding-top: 10vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 72vw;
        height: 86vw;
    }
    .cardheadings-product {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        color: #4E490F;
    }
    .paragra-product {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 150%;
        text-align: center;
        color:  #706A1A;
        padding: 5vw;
        margin: 5vw;
    }

    .dotlineimgg {
        padding: 10px;
        width: 44vw;
        height: 8vw;
    }
    .time-savingg-product h1 {
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 8vw;
        line-height: 150%;
        align-items: center;
        text-align: center;
        color: #4E490F;
        /* height: 60px; */
        /* padding-top: 20px; */
    }
    .time-savingg-product {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .mobprodcons {
        display: block;
        margin: auto;
        width: 81vw;
        height: 96vw;
    }
    .consolidatedd {
        text-align: center;
        height: auto;
        width: 100vw;
        padding-top: 18vw;
    }
    .consolidated-platformm {
        background: #FFFFFF;
        height: auto;
        width: 100vw;
    }
    .lastdiv-product {
        background: linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%);
        height: 22vw;
        width: 100vw;
    }

}