.Card-Container {
    width: 15rem;
    height: auto;
    aspect-ratio: 16/9;
    position: relative;

    h3 {
        font-family: Lato;
        font-size: 25px;
        font-weight: 800;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: center;
        color: #33544E;
    }

    img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: all 0.1s linear;
    }

    p {
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #456E66;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Number of lines to display before truncating */
        -webkit-box-orient: vertical;
        transition: all 0.1s linear;
    }

    span {
        font-family: Jost;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: center;
        color: #35BBA3;
        text-decoration: none;
        position: relative;
        bottom: 10%;
    }

    span:hover {
        cursor: pointer;
    }

}
@media (min-width:768px) and (max-width:1025px) {

    .Card-Container {
        transform: scale(0.6);
    }
    
}


.AnimationCard {
    // border: 2px solid #35BBA3;
    // border-radius: 20px;
    background: #FFFDFD;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 15px;

    img {
        transform: scale(0.6);
        transition: all 0.1s linear;
    }

    p {
        overflow: unset;
        text-overflow: inherit;
        display: block;
        transition: all 0.1s linear;
        padding: 0.8rem 0.4rem;
    }

    span {
        display: none;
    }
}