.containerEL {
  width: 100vw !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
}

.containerEL>svg {
  overflow: unset;
}

.marker {
  fill: green;
  stroke: green;
  stroke-width: 40px;
}