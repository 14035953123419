.modalStyle {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20% 0;
    overflow-y: hidden !important;
    z-index: 5000;
    background-color: rgba(29, 79, 95, 0.3) !important;

}

.modal.fade .modal-dialog {
    width: 600px;
    height: 590px;
    border-radius: 29px;
    background-color: transparent !important;
    /* background: linear-gradient(180deg, #FFFFFF 0%, #C4DBE0 51.59%, #448093 103.97%); */
    background: linear-gradient(177.99deg, #DCFFF9 -6.68%, #DCFFF9 71.85%, #2A7086 143.41%) !important;
    position: relative;
    padding-bottom: 6vw;
}

.modal-open {
    overflow: hidden !important;
}



.modal-header {
    border: none;
}

.modal-content {
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
}

.modal-body {
    color: white;
    position: relative;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /*border: 2px solid red;*/
}

.popUp-main {
    position: relative;
    height: auto;
}

.PopupContent {
    width: 100%;
    /* padding-left: 2rem !important; */
}

.data-cenetr-inner {
    width: 430px;
    padding-top: 1.5rem;
}

.data-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .popupText {
    font-size: 96px;
    font-weight: 700;
    line-height: 144px;
    letter-spacing: 0em;
    text-align: center;
    padding: 1.5rem;
    color: #065849;
} */

.popupText h3 {
    font-family: 'Raleway';
    font-size: 40px;
    font-weight: 400;
}

.popupText h3 span {
    font-family: 'Raleway';
    font-size: 48px;
    font-weight: 700;
}

.popupText h2 {
    font-family: Atma;
    font-size: 70px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: #065849;
}

.popupText h2>span {
    font-family: Atma;
    font-size: 70px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #065849;
}

.popupText p {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #065849;
    padding: 0.9rem 0rem;
}

.Second-Section {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Jost';
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 7rem;
    padding-right: 6rem;
    color: #1F5262;
}

.Second-Section h3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    font-family: 'Jost';
}

.Second-Section h3 span {
    font-weight: 800;
    text-shadow: 2px 0px #1F5262;
}

.Second-Section p {
    font-size: 35px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    font-family: 'Jost';
    font-style: italic;
}

.backToHome {
    height: 45px !important;
}

.login-btn_popup {
    margin-top: 4% !important;
    margin-left: 0 !important;
}

.data_container .data_input {
    margin-bottom: 0.5rem !important;
}

.data_container .data_input input {
    width: 100%;
    height: 44px;
    margin: 0.3rem;
    padding: 1rem;
    border-radius: 50px;
    border: 1px solid #2A7086;
    box-shadow: 0px 0px 8px 0px #33544E;
    outline: none;
}

.data_container .data_input label {
    width: 100%;
    height: auto;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #1C5466;
    text-align: left;
}

#disabledSelect {
    width: 101%;
    font-family: 'Jost';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    height: 60px;
    padding: 1rem;
    border-radius: 50px;
    outline: none;
    color: #1C5466;
    border: 1px solid #2A7086;
    box-shadow: 0px 0px 8px 0px #33544E;
}

#disabledSelect option {
    font-family: 'Jost';
    font-size: 16px;
    font-weight: 300;
    color: #465154;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #F3F9FB;
    border-bottom: 1px solid black;
}

#disabledSelect option::selection {
    background-color: #E8F2F5 !important;
}

#disabledSelect option::-webkit-selection {
    background-color: #E8F2F5 !important;
}

#disabledSelect option::-moz-selection {
    background-color: #E8F2F5 !important;
}

input::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #C2C2C2;
    padding-left: 0.5rem;
    opacity: 0.5;
}

.btn-close {
    position: absolute;
    right: 2vw;
    top: 2vw;
    z-index: 11 !important;
    background: transparent url("../../Images/closebtn.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.buybtn-poPup {
    height: 44px;
    width: 220px;
    border-radius: 40px;
    margin-top: 1.5vw;
    font-family: 'Raleway';
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    border-radius: 4px;
    border: none;
    background-color: #065849;
    box-shadow: 0px 0px 8px 0px #1C5466;
    outline: none;
    /* transform: skew(-10deg); */
}

.defualt-colorFont {
    font-family: 'Jost';
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #465154 !important;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

@media screen and (max-width:480px) {
    /* .modal {
        height: 750px !important;
    } */

    .modal.fade .modal-dialog {
        /* width: 420px;
        margin-top: 30vw !important; */
        width: 95%;
        border-radius: 29px;
        background-color: transparent !important;
        /* background: linear-gradient(180deg, #FFFFFF 0%, #C4DBE0 51.59%, #448093 103.97%); */
        background: linear-gradient(177.99deg, #DCFFF9 -6.68%, #DCFFF9 71.85%, #2A7086 143.41%) !important;
        position: relative;
    }

    .btn-close {
        position: absolute;
        right: 7vw;
        top: 3vw;
        z-index: 11 !important;
        background: transparent url("../../Images/closebtn.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }

    .login-popUpbtn {
        background: #44B0C8;
        border-radius: 30px;
        text-align: center;
        margin-top: 1.5rem;
    }

    .login-popUpbtn button {
        color: white;
        font-size: 20px;
        font-weight: 700;
    }
}