@import url('https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=EB+Garamond&family=Lobster&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.demo-rectangle {
    display: flex;
    justify-content: center;
    padding-top: 150px;
    align-items: center;
    height: 100vh;
}

.inside-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.demo-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.demo-image img {
    max-width: 100%;
    max-height: 100%;
}

.demo-form {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .Second-Section{
        padding-top: 3rem;
        padding-left: inherit !important;
        padding-right: inherit !important;
        
        a{
            text-decoration: none;
        }
    }
}

.demo-form form {
    padding: 20px;
    border-radius: 5px;
}

.form-wrapper {
    max-width: 500px;
    padding: 20px;
    background-color: #fff;
    text-align: center;
}

.form-wrapper h2 {
    margin-top: 0;
    font-family: 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 150%;
    color: #065849;
}

.bold-contact {
    font-weight: bold;
}

.form-wrapper p {
    margin-bottom: 20px;
    text-align: justify;
    padding-left: 25px;
    padding-right: 25px;     /*neither justify or center is working*/
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    color: #065849;
}

.form-wrapper input[type="text"] {
    width: 400px;
    padding: 10px;
    border: none;
    margin-bottom: 20px;
    background: #FFFCFC;
    box-shadow: 0px 0px 8px #33544E;
    border-radius: 50px;
    outline: none;
    padding-left: 1rem;

}

.form-wrapper input[type="text"]::placeholder {
    color: #B8DAD4;
}

.form-wrapper button {
    padding: 10px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: white;
    background: #065849;
    box-shadow: 0px 0px 8px #1C5466;
    border-radius: 31px;
    width: 150px;
    margin: auto;
    display: block;
}

.last-contact{
    background: linear-gradient(180deg, #FFFDFD 0%, #DCFFF9 100%);
    height: 200px;
}