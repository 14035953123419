@media screen and (min-width: 769px) and (max-width:1025px){}



@media screen and (min-width: 426px) and (max-width: 769px) {
    .demo-rectangle {
        display: block;
        justify-content: center;
        padding-top: 49vw;
        align-items: center;
        height: auto;
        width: 100vw;
    }

    .demo-image img {
        /* max-width: 100%; */
        /* max-height: 100%; */
        width: 86vw;
        height: 85vw;
        margin: auto;
    }

    .demo-image {
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .demo-form {
        flex: 1 1;
        /* display: flex; */
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100vw;
    }

    .form-wrapper {
        max-width: 87vw;
        padding: 20px;
        background-color: #fff;
        text-align: center;
    }

    .form-wrapper h2 {
        margin-top: 0;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        color: #065849;
    }
    .form-wrapper p {
        margin-bottom: 20px;
        text-align: justify;
        padding-left: 4vw;
        padding-right: 4vw;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 4vw;
        line-height: 150%;
        color: #065849;
    }

    .demo-form form {
        max-width: 80%;
        padding: 10vw;
        border-radius: 5px;
    }
    .form-wrapper input[type="text"] {
        width: 56vw;
        padding: 1vw;
        border: none;
        margin-bottom: 20px;
        background: #FFFCFC;
        box-shadow: 0px 0px 8px #33544E;
        border-radius: 50px;
        outline: none;
        padding-left: 1rem;
    }
    .form-wrapper button {
        padding: 7px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: white;
        background: #065849;
        box-shadow: 0px 0px 8px #1C5466;
        border-radius: 31px;
        width: 28vw;
        display: block;
        margin-left: 15vw;
    }
    .last-contact {
        background: linear-gradient(180deg, #FFFDFD 0%, #DCFFF9 100%);
        height: 28vw;
    }

  } 
  @media screen and (min-width: 377px) and (max-width: 426px) {
    .demo-rectangle {
        display: block;
        justify-content: center;
        padding-top: 150px;
        align-items: center;
        height: 185vh;
        max-width: 95vw;
    }
    .demo-image {
        /* flex: 1 1; */
        /* display: flex; */
        justify-content: center;
        align-items: center;
    }
    .demo-image img {
        max-width: 100%;
        max-height: 100%;
        width: 80vw;
    }
    .demo-form {
        /* flex: 1 1; */
        /* display: flex; */
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 95vw;
        padding-top: 14vw;
    }
    .form-wrapper {
        max-width: 87vw;
        padding: 20px;
        background-color: #fff;
        text-align: center;
    }
    .form-wrapper h2 {
        margin-top: 0;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        color: #065849;
    }
    .form-wrapper p {
        margin-bottom: 20px;
        text-align: justify;
        padding-left: 4vw;
        padding-right: 4vw;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 4vw;
        line-height: 150%;
        color: #065849;
    }
    .demo-form form {
        max-width: 80%;
        padding: 14vw;
        border-radius: 5px;
    }
    .form-wrapper input[type="text"] {
        width: 56vw;
        padding: 1vw;
        border: none;
        margin-bottom: 20px;
        background: #FFFCFC;
        box-shadow: 0px 0px 8px #33544E;
        border-radius: 50px;
        outline: none;
        padding-left: 1rem;
    }
    .form-wrapper button {
        padding: 7px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: white;
        background: #065849;
        box-shadow: 0px 0px 8px #1C5466;
        border-radius: 31px;
        width: 28vw;
        display: block;
        margin-left: 15vw;
    }
    .last-contact {
        background: linear-gradient(180deg, #FFFDFD 0%, #DCFFF9 100%);
        height: 38vw;
    }
      
}

@media (min-width: 321px) and (max-width: 376px) {
    .demo-rectangle {
        display: block;
        justify-content: center;
        padding-top: 49vw;
        align-items: center;
        height: auto;
        width: 100vw;
    }

    .demo-image img {
        /* max-width: 100%; */
        /* max-height: 100%; */
        width: 86vw;
        height: 85vw;
        margin: auto;
    }

    .demo-image {
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .demo-form {
        flex: 1 1;
        /* display: flex; */
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100vw;
    }

    .form-wrapper {
        max-width: 87vw;
        padding: 20px;
        background-color: #fff;
        text-align: center;
    }

    .form-wrapper h2 {
        margin-top: 0;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        color: #065849;
    }
    .form-wrapper p {
        margin-bottom: 20px;
        text-align: justify;
        padding-left: 4vw;
        padding-right: 4vw;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 4vw;
        line-height: 150%;
        color: #065849;
    }

    .demo-form form {
        max-width: 80%;
        padding: 10vw;
        border-radius: 5px;
    }
    .form-wrapper input[type="text"] {
        width: 56vw;
        padding: 1vw;
        border: none;
        margin-bottom: 20px;
        background: #FFFCFC;
        box-shadow: 0px 0px 8px #33544E;
        border-radius: 50px;
        outline: none;
        padding-left: 1rem;
    }
    .form-wrapper button {
        padding: 7px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: white;
        background: #065849;
        box-shadow: 0px 0px 8px #1C5466;
        border-radius: 31px;
        width: 28vw;
        display: block;
        margin-left: 15vw;
    }
    .last-contact {
        background: linear-gradient(180deg, #FFFDFD 0%, #DCFFF9 100%);
        height: 28vw;
    }

}


@media (max-width: 320px){
    .demo-rectangle {
        display: block;
        justify-content: center;
        padding-top: 49vw;
        align-items: center;
        height: auto;
        width: 100vw;
    }

    .demo-image img {
        /* max-width: 100%; */
        /* max-height: 100%; */
        width: 86vw;
        height: 85vw;
        margin: auto;
    }

    .demo-image {
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
    }
    .demo-form {
        flex: 1 1;
        /* display: flex; */
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100vw;
    }

    .form-wrapper {
        max-width: 87vw;
        padding: 20px;
        background-color: #fff;
        text-align: center;
    }

    .form-wrapper h2 {
        margin-top: 0;
        font-family: 'Atma';
        font-style: normal;
        font-weight: 400;
        font-size: 7vw;
        line-height: 150%;
        color: #065849;
    }
    .form-wrapper p {
        margin-bottom: 20px;
        text-align: justify;
        padding-left: 4vw;
        padding-right: 4vw;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 4vw;
        line-height: 150%;
        color: #065849;
    }

    .demo-form form {
        max-width: 80%;
        padding: 10vw;
        border-radius: 5px;
    }
    .form-wrapper input[type="text"] {
        width: 56vw;
        padding: 1vw;
        border: none;
        margin-bottom: 20px;
        background: #FFFCFC;
        box-shadow: 0px 0px 8px #33544E;
        border-radius: 50px;
        outline: none;
        padding-left: 1rem;
    }
    .form-wrapper button {
        padding: 7px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: white;
        background: #065849;
        box-shadow: 0px 0px 8px #1C5466;
        border-radius: 31px;
        width: 28vw;
        display: block;
        margin-left: 15vw;
    }
    .last-contact {
        background: linear-gradient(180deg, #FFFDFD 0%, #DCFFF9 100%);
        height: 28vw;
    }


    

  }









